.reset-password {
  background: linear-gradient(to bottom right, #2e4692, #171b31);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;

  &__input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 350px;
    width: 80%;
    padding: 4rem 1rem 0.8rem;
    background-color: rgb(245, 247, 252);
    border-radius: 8px;
    margin: auto;
    margin-top: 90px;
    box-shadow: 0 10px 15px rgb(110, 120, 145);
  }
  &__prompt {
    color: #333;
    font-size: 1.15rem;
    margin: 1rem 0;
    margin: auto;
    padding: 0rem 0 2rem;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    margin: auto;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);

    &--upper {
      font-family: "Fellix-Bold";
      font-weight: 800;
      font-size: 1.4rem;
    }
    &--lower {
      font-family: "Fellix-Regular";

      letter-spacing: 0.27rem;
      font-size: 1rem;
    }
  }
}
