.referrals {
  background-color: white;
  padding-bottom: 20px;
  min-height: 100vh;

  &__content-wrap {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(to bottom right, #e8f4f8, #f7fbfd);
  }

  &__prompt {
    text-align: center;
    margin: auto;
    margin-top: 50px;
    max-width: 430px;
  }

  &__banner {
    width: 100%;
    height: 200px;
    box-shadow: 0px 10px 20px rgba(89, 126, 156, 0.8);
    color: white;

    background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/referrals-bg.png");
    background-size: cover;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
  }

  &__back-arrow {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 7px;
    font-size: 2rem;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  }

  &__text-dark {
    color: #162947;
    margin: auto;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-family: "DM Sans", sans-serif;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__text-white {
    color: #fff;
    margin: auto;
    margin-bottom: 70px;
    font-size: 1.6rem;
    max-width: 500px;
    text-align: center;
  }

  &__text-conditions {
    color: #fff;
    margin-top: 10px;
    font-size: 0.7rem;
  }

  &__copy-container {
    padding: 40px 0px;
    padding-top: 20px;
    max-width: 450px;
    width: 100%;
    text-align: center;
    margin: auto;
  }

  &__link-container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__earned--wrap {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__input-clone {
    height: 50px;
    position: relative;
    color: #1c1c1e;
    font-size: 1.6rem;
    padding: 0 1rem;
    border: 1px solid #d7dae0;
    background-color: white;
    border-radius: 0.6rem;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.8);
    &--highlight {
      background-color: #4d7bd7;
    }
  }
  &__link {
    font-size: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    margin: auto;
    color: #5d6169;
    height: 50px;
    overflow: hidden;
    &--highlight {
      font-family: "Fellix-Bold";
      color: white;
    }
  }
  &__copy {
    margin: auto;
    text-shadow: 0px 1px 2px white;
    font-family: "Fellix-Bold";
    cursor: pointer;
    z-index: 3;

    color: #4d7bd7;
    font-size: 1rem;
    font-weight: 700;
    width: 50px;
    cursor: pointer;
    white-space: nowrap;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    i {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 140%;
      height: 100%;
      left: -20%;
      z-index: -1000;
      transition: all ease-in-out 0.5s;
      background-repeat: no-repeat;
    }
    &:before {
      display: none;
      top: -75%;
      background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, transparent 20%, #162947 20%, transparent 30%),
        radial-gradient(circle, #f18659 20%, transparent 20%),
        radial-gradient(circle, #4d7bd7 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #dc659c 15%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #4d7bd7 20%, transparent 20%),
        radial-gradient(circle, #e9b255 20%, transparent 20%);
      background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
        15% 15%, 10% 10%, 18% 18%;
    }
    &:after {
      display: none;
      bottom: -75%;
      background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #162947 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #f18659 15%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #4d7bd7 20%, transparent 20%),
        radial-gradient(circle, #e9b255 20%, transparent 20%);
      background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
        20% 20%;
    }
    &--animate {
      &:before {
        display: block;
        animation: topBubbles ease-in-out 0.75s forwards;
      }
      &:after {
        display: block;
        animation: bottomBubbles ease-in-out 0.75s forwards;
      }
    }
  }

  &__earned {
    &--container {
      margin: auto;
      max-width: 210px;
      background-color: white;
      padding: 2.3rem 3rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.8);
    }

    &--heading {
      font-size: 1.3rem;
      margin-bottom: 43px;
    }
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 1.1rem;
      font-family: "Fellix";
    }
    &--payout {
      text-align: center;
      margin-bottom: 4rem;
      margin: auto;
      width: 340px;
    }
    &--blue-text {
      color: #4d7bd7;
      text-shadow: 0px 1px 2px white;
      font-family: "Fellix-Bold";
      margin: auto;
    }
    &--small-text {
      font-size: 0.8rem;
    }
    &--med-text {
      font-size: 0.93rem;
    }
    &--wise {
      text-decoration: underline;
      color: #4d7bd7;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-top: 10px;
  }

  &__input-email {
    width: 67%;
    margin-bottom: 0px;
  }

  &__change-email-wrapper {
    display: flex;
    flex-direction: column;
    width: 20%;
    text-align: left;
  }

  &__change-email {
    text-decoration: underline;
    cursor: pointer;
    color: #333;
  }
  &__email-desc {
    font-size: 0.8rem;
    margin: auto;
    margin-top: 10px;

    width: 80%;
    color: rgb(100, 100, 100);
  }

  &__referral-data {
    background-color: #f6f7f9;
    box-shadow: 0px 5px 10px rgba(89, 126, 156, 0.8);
    border-radius: 8px;
    margin: auto;
    margin-top: 20px;

    padding: 0px 5px 20px;
    max-width: 600px;
    position: relative;

    width: 90%;
    text-align: left;
    display: flex;
    flex-direction: column;

    &--subscribed {
      color: rgb(0, 179, 136);
    }

    &--title {
      margin: auto;
      padding: 17px;
    }

    &--row {
      background-color: white;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      margin: auto;
      padding: 10px;
      width: 90%;
      border-bottom: 1px dashed #d3d3d3;
      border-left: 1px dashed #d3d3d3;
      border-right: 1px dashed #d3d3d3;
    }

    &--row-title {
      background-color: #4d7bd7;
      color: white;
      border-top: 1px dashed #d3d3d3;
    }
    &--first-item {
      padding: 10px;
      width: 27%;
      text-align: left;
    }

    &--second-item {
      padding: 10px;
      width: 27%;
      text-align: left;
    }
  }

  @media only screen and (max-width: 500px) {
    &__content-wrap {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: space-evenly;
    }

    &__text-white {
      color: #fff;
      margin: auto;
      margin-bottom: 50px;
      font-size: 1.2rem;
      max-width: 70%;
      text-align: center;
    }

    &__link {
      position: absolute;
      top: 0;
      left: 5%;
      width: 90%;
    }

    &__copy-container {
      width: 80%;
      padding-top: 20px;
    }
    &__link-container {
      margin-top: 20px;
      margin-bottom: 0px;
      width: 100%;
    }
    &__earned--wrap {
      margin-top: 0px;
      margin-bottom: 40px;
    }
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
