.questions {
  &__heading {
    margin: auto;
    text-align: center;
    margin-top: 17px;
    // margin-bottom: -23px;
    color: #2948ff;
    font-size: 1.4rem;

    border-radius: 4px;
    padding: 8px 12px;
  }

  &__question {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__check {
    color: #50c878;

    font-size: 2rem;
  }

  &__times {
    color: #f64747;

    font-size: 2rem;
  }

  &__score {
    margin: 20px 0px;
    font-size: 1.4rem;
  }

  &__answers-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
    justify-content: space-around;
  }
  &__answer {
    width: 90px;
    height: 57px;
    border-radius: 7px;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    margin: 0 17px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__answer--selected {
    background-color: #2948ff;
    color: #fff;
  }
}
