.tracker {
  background-color: white;
  height: 100%;

  &--word {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
    margin-left: 0.2rem;
    background-color: #fff;
    padding: 3px 10px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 #cbdbdf;
  }
  &__back-home-arrow {
    width: 100%;

    position: fixed;
    height: 70px;
    background-color: white;
    box-shadow: 0 2px 10px 0 #a5a5a5;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--img {
      height: 34px;
      max-width: 80px;
      padding: 10px 20px;
      margin-top: 7px;

      &:hover {
        padding-left: 5px;
        padding-right: 35px;
        transition: 0.2s;
      }
    }

    &--right-flex {
      width: 120px;
    }

    &--total-tracked {
      padding: 25px 30px;
      font-size: 1.7rem;
      align-self: center;
    }
  }
  &__tracking-container {
    background-color: rgba(233, 220, 214, 0.2);
    height: 100vh;
    overflow-y: auto;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    padding-top: 4.5rem;
    &--modalize {
      background-color: whitesmoke;
      min-width: 75%;
      width: 75%;
      height: 42.3vh;
      overflow-y: scroll;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      bottom: 1%;
      border-radius: 10px;
      position: fixed;
    }
    &--title {
      background-color: #f4ede3;
      margin: auto;
      padding: 2% 1%;
      font-size: 1.2rem;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      display: flex;
      flex-direction: row;

      &--modalize {
        background-color: whitesmoke;
        text-align: left;
        width: 61%;
        font-size: 1.2rem;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        display: flex;
        flex-direction: row;
        padding: 4% 7%;
        bottom: 43%;

        border-radius: 10px;
        position: fixed;
      }
    }

    &--number {
      font-size: 2rem;
      font-weight: 700;
      color: #e5532a;
      display: inline-block;
      margin-right: 5px;
      margin-left: 2.5rem;
    }
  }

  &__tracking-video-container {
    height: 500px;
    width: 50%;
    margin: auto;
  }

  &__tracking-section {
    display: flex;

    flex-direction: row;
    margin: auto;
    padding: 5% 12%;
    border-top: 1px solid #333;
    align-items: center;
    text-align: center;

    &--top {
      display: flex;

      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
      justify-content: space-between;
    }

    &--flex-row {
      display: flex;
      flex-direction: column;
    }

    &--word-wrapper {
      min-width: 100px;
    }

    &--times-clicked {
      min-width: 23vw;
    }

    &--phonic-word-container {
      display: flex;
      flex-direction: row;
      min-width: 30vw;
      align-items: center;
    }

    &--phonic-part-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      cursor: pointer;
    }

    &--phonic-part {
      padding-bottom: 4px;
    }

    &--remove-word {
      border: none;
      background-color: transparent;
      font-family: "Fellix";
      margin-left: auto;
    }
    align-self: center;
    &--minus-icon {
      padding: 10px 17px;
      border: 1.2px solid #50c878;
      border-radius: 8px;
      color: #50c878;
      font-size: 1.2rem;
      font-style: normal;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0 1px 4px #50c878;
      }
    }
    &--display-phonics {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    &--save {
      background-color: white;
      border: none;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      margin-left: 30px;
    }
    &--save-success {
      font-size: 1rem;
      margin-left: 30px;
      margin-top: 7px;
    }

    &-chevron {
      position: fixed;
      bottom: 0;
      font-size: 200%;
    }
    &--phonic-click {
      width: 2.5rem;
      height: 2rem;
      border: 1px solid black;
      &-small {
        width: 2rem;
        height: 1.7rem;
        border: 0.5px solid black;
      }
    }

    &--login-prompt {
      opacity: 1;
      z-index: 1;
      position: absolute;
      left: 23%;
      bottom: 20%;
      border: none;
      background-color: white;
      box-shadow: 0 2px 10px 0 #9bbac2;
      font-size: 1.2rem;
      border-radius: 6px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 40px 10px;
      width: 50%;
      transition: all 0.25s;

      &:hover {
        background-color: #5854a1;
        color: white;
      }
    }
  }
  &__phonic-part {
    &--first {
      color: #f9a557;
      font-family: "Fellix-Bold";
    }

    &--second {
      color: #ff7222;
      font-family: "Fellix-Bold";
    }

    &--middle {
      color: #e35072;
      font-family: "Fellix-Bold";
    }

    &--penultimate {
      color: #b466d2;
      font-family: "Fellix-Bold";
    }

    &--last {
      color: #3eb8cb;
      font-family: "Fellix-Bold";
    }
  }

  &__phonic-click {
    &--first {
      background-color: #f9a557;
    }

    &--second {
      background-color: #ff7222;
    }

    &--middle {
      background-color: #e35072;
    }

    &--penultimate {
      background-color: #b466d2;
    }

    &--last {
      background-color: #3eb8cb;
    }
  }

  @media only screen and (max-width: 500px) {
    iframe {
      width: 150%;
      margin-left: -23%;
    }
    &__back-home-arrow--total-tracked {
      font-size: 1rem;
    }
    &__tracking-section {
      flex-direction: column;
      padding: 5% 5%;

      &--word-wrapper {
        text-align: left;
      }

      &--top {
        display: flex;
        width: 300px;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        justify-content: space-between;
      }
      &--remove-word {
        margin: 3% auto 5%;
        height: 63px;
      }
    }
  }
}
