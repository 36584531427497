.verification {
  color: white;
  &__main {
    display: flex;
    flex-direction: column;
  }
  &__heading {
    font-size: 3rem;
    margin: 20vh auto 2rem;
  }
  &__button {
    font-size: 1.5rem;
    margin: 2rem auto;
    padding: 2rem 5rem;
    width: 63%;
    color: white;
    background-color: #50c878;
    text-align: center;
    border-radius: 10px;
    transition: 0.2s;

    animation-name: wiggle;

    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: wiggle;
    -ms-animation-name: wiggle;
    -ms-animation-duration: 4s;

    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    &:hover {
      box-shadow: 0 5px 15px #50c878;
    }
  }
}
