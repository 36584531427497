.footer {
  padding: 4rem 2rem;
  // background-image: $reverse-gradient;
  color: white;
  font-family: "Fellix-SemiBold";
  background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/space-bg-footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &__img {
    width: 95%;
    position: absolute;
  }

  &__wrapper {
    display: grid;
    grid-gap: 1rem;
    align-items: flex-start;
  }

  // ! newsletter and subscription wrapper ========================================
  &__left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  // ! navigation links wrapper ========================================
  &__nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav-links-wrapper,
  &__policies-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav-links-wrapper {
    margin-bottom: 3rem;
  }

  &__nav-header {
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid #fff;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav-item {
    font-family: "Fellix-SemiBold";
    color: white;
    cursor: pointer;
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
