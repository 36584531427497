.header {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1.5rem;
  color: white;
  position: relative;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.9), transparent);
  &__logged-out {
    align-items: center;
  }

  i {
    font-size: 20px;
    margin: 0 0.7rem;
  }

  &__fa-fa {
    margin-left: 0rem !important;
  }

  &__wrapper {
    margin-bottom: 20px;
    // &--logged-out {
    // margin-bottom: 33%;
    // }
  }

  &__parent-back {
    display: flex;
    flex-direction: row;
    align-items: left;
    text-align: left;

    color: white;

    font-family: "Fellix-Bold";
    padding: 8px 14px;
    border-radius: 4px;

    &:hover {
      font-family: "Fellix-Bold";
      padding-right: 18px;
      padding-left: 10px;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-top: 7px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);

    &--upper {
      font-family: "Fellix-Bold";
      font-weight: 800;
      font-size: 2.5rem;
    }
    &--lower {
      font-family: "Fellix-Regular";

      letter-spacing: 0.27rem;
      font-size: 2rem;
    }
  }

  &__nav-options-container {
    margin: 1.8rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    &--mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &__nav-option {
    margin: 0 0.5rem;

    transition: all 0.2s ease-in-out;
    &:hover {
      font-family: "Fellix-Bold";
    }
  }

  &__nav-option-2 {
    margin: 0 0.9rem;

    color: white;
    padding: 10px 14px;
    border-radius: 4px;

    font-family: "Fellix-Bold";

    transition: all 0.2s ease-in-out;
    &:hover {
      font-family: "Fellix-Bold";
      transform: translateY(-2px);
    }
  }

  &__icons-container {
    display: flex;
    flex-direction: row;
    margin: 1.8rem 0.5rem;
    position: absolute;
    right: 40px;
  }

  &__arrow {
    position: absolute;
    right: 20px;
    top: 5px;
  }

  &__user {
    display: flex;
    flex-direction: column;
    width: 187px;
    margin: auto;
    align-items: center;
  }

  &__profile-wrapper {
    margin-bottom: 5px;
  }

  &__image-wrapper {
    background-color: #b994ff;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 7px;
    position: relative;
    margin: auto;
    margin-top: -12px;
    margin-bottom: 5px;

    > img {
      width: 35px;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &__user-name {
    margin: auto;
    font-weight: 700;
    font-size: 1rem;
  }

  &__user-options {
    &--option {
      cursor: pointer;
      padding: 17px 17px;
      transition: 0.2s;
      &:hover {
        padding: 21px 17px;
        background-color: #e5f6e8;
        box-shadow: 0px 0px 10px 0px #50c878;
        transition: 0.2s;
        border-radius: 5px;
      }
    }

    &--dropdown {
      z-index: 999999;
      display: flex;
      flex-direction: column;
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 5px;
      min-width: 130px;
      transition: 0.2s;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      a {
        padding: 17px 17px;
        &:hover {
          padding: 21px 17px;
          background-color: #e5f6e8;
          box-shadow: 0px 0px 10px 0px #50c878;

          transition: 0.2s;
          border-radius: 5px;
        }
      }
    }
  }
  &__dropdown-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__user-prompts {
    transform: translateY(-5px);
    margin-right: 30px;
    &--login {
      border: none;
      background-color: transparent;
      color: white;
      font-size: 1rem;
    }
    &--register {
      border: none;
      background-color: #2948ff;
      color: white;
      font-size: 1rem;
      border-radius: 3px;
      transition: all 0.2s ease-in-out;
      // animation-name: wiggle-register;

      animation-duration: 7s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      // -webkit-animation-name: wiggle-register;
      // -ms-animation-name: wiggle-register;
      -ms-animation-duration: 7s;

      -webkit-animation-duration: 7s;
      -webkit-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
      -ms-animation-timing-function: ease-in-out;
      &:hover {
        box-shadow: 0px 1px 3px #000;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    &__logo {
      margin-left: 0.5rem;

      &--upper {
        font-size: 2.25rem;
      }

      &--lower {
        font-size: 1.8rem;
      }
    }

    &__user-name {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    }

    &__icons-container {
      right: 0px;
      margin: 1.2rem 0.2rem;
    }
  }
}

.responsive-nav {
  display: none;
  flex-direction: column;
  top: 20rem;
  right: 0;
  left: 0;
  max-height: 0;
  background-color: #ffffff;
  z-index: 10;
  overflow: hidden;
  transition: max-height 0.4s ease-out;

  &.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 6rem;
    max-height: 100vh;
    transition: max-height 0.7s ease-out;
  }
}

.hamburger-btn {
  display: block;
  position: absolute;
  top: 25%;
  right: 4%;
  width: 40px;
  height: 24px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 20;
}

.hamburger-btn .line {
  display: block;
  background: #cccbcb;
  width: 30px;
  height: 3px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  border-radius: 50px;
  transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.hamburger-btn .line.line-1 {
  top: 0;
}

.hamburger-btn .line.line-2 {
  top: 50%;
  margin-top: -2px;
  width: 19px;
  right: 26%;
  left: auto;
}

.hamburger-btn .line.line-3 {
  top: 20px;
}

.hamburger-btn:hover .line-1,
.hamburger-btn:focus .line-1 {
  -ms-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  transform: translateY(-4px);
}

.hamburger-btn:hover .line-3,
.hamburger-btn:focus .line-3 {
  -ms-transform: translateY(4px);
  -moz-transform: translateY(4px);
  transform: translateY(4px);
}

.hamburger-btn.active .line-1 {
  -ms-transform: translateY(25px) translateX(0) rotate(45deg);
  transform: translateY(25px) translateX(0) rotate(45deg);
  -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
  -moz-transform: translateY(10px) translateX(0) rotate(45deg);
}

.hamburger-btn.active .line-2 {
  opacity: 0;
}

.hamburger-btn.active .line-3 {
  -ms-transform: translateY(-25px) translateX(0) rotate(-45deg);
  transform: translateY(-25px) translateX(0) rotate(-45deg);
  -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
  -moz-transform: translateY(-10px) translateX(0) rotate(-45deg);
}

@-webkit-keyframes wiggle-register {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-2deg);
  }
  40% {
    -webkit-transform: rotate(4deg);
  }
  60% {
    -webkit-transform: rotate(-1deg);
  }
  80% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle-register {
  0% {
    -ms-transform: rotate(0deg);
  }
  20% {
    -ms-transform: rotate(-2deg);
  }
  40% {
    -ms-transform: rotate(4deg);
  }
  60% {
    -ms-transform: rotate(-1deg);
  }
  80% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle-register {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-2deg);
  }
  40% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-1deg);
  }
  80% {
    transform: rotate(0deg);
  }
}
