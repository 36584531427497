.tic-tac-toe {
  height: 100vh;
  position: relative;
  font-family: "Fellix-SemiBold";
  background: linear-gradient(-45deg, #78ffd6, #38ef7d, #0575e6, #0cebeb);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  overflow: hidden;

  &__z-wrapper {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 90vw;
    max-width: 600px;
    border-radius: 8px;
    height: 470px;
    background: rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }

  &__z-wrapper-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 90vw;
    max-width: 1200px;
    border-radius: 8px;
    height: 97vh;
    max-height: 1200px;
    background: rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }

  &__bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    & li {
      position: absolute;
      list-style: none;
      display: block;
      width: 40px;
      height: 40px;
      font-size: 3rem;
      color: white;
      background-color: transparent;
      bottom: -160px;
      -webkit-animation: x-o 10s infinite;
      animation: x-o 10s infinite;

      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;

      &:nth-child(1) {
        left: 10%;
      }

      &:nth-child(2) {
        left: 20%;

        width: 80px;
        height: 80px;

        animation-delay: 2s;
        animation-duration: 17s;
      }

      &:nth-child(3) {
        left: 25%;
        animation-delay: 4s;
      }

      &:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;

        animation-duration: 22s;

        background-color: fade(white, 25%);
      }

      &:nth-child(5) {
        left: 70%;
      }

      &:nth-child(6) {
        left: 80%;
        width: 120px;
        height: 120px;

        animation-delay: 3s;
        background-color: fade(white, 20%);
      }

      &:nth-child(7) {
        left: 32%;
        width: 160px;
        height: 160px;

        animation-delay: 7s;
      }

      &:nth-child(8) {
        left: 55%;
        width: 20px;
        height: 20px;

        animation-delay: 15s;
        animation-duration: 40s;
      }

      &:nth-child(9) {
        left: 25%;
        width: 10px;
        height: 10px;

        animation-delay: 2s;
        animation-duration: 40s;
        background-color: fade(white, 30%);
      }

      &:nth-child(10) {
        left: 90%;
        width: 160px;
        height: 160px;

        animation-delay: 11s;
      }
    }
  }

  &__finish {
    position: absolute;
    color: black;
    background: white;
    opacity: 0.8;
    width: 100%;
    height: 500px;
    text-align: center;
    &--text {
      position: relative;
      top: 45%;
      font-size: 3rem;
    }
  }

  &__turn-indicator {
    height: 20px;
  }

  &__span {
    font-size: 4rem;
    font-family: "Fellix-SemiBold";
    color: #fff;
    background: #272727;
    width: 100%;
    text-align: center;
    border-radius: 5px;
  }

  &__headline {
    color: #272727;
    padding: 20px 10px 10px;
    text-align: center;
    font-size: 3rem;
  }

  &__board {
    border: 1rem solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    box-shadow: 0 15px 25px rgba(0, 5px, 10px, 0.7);
    width: 90%;
    border-radius: 7px;
    margin: auto;
    height: 60vh;
    max-height: 500px;
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    gap: 0.3rem;
  }
  &__squares {
    background: #272727;
    display: abolute;
    border-radius: 2px;
    border: none;
    font-size: 5rem;
    /* font-weight: 800; */
    cursor: pointer;
    outline: none;
    color: white;

    &--winner {
      background: #50c878;
      box-shadow: 0 5px 15px #50c878;
    }
  }

  &__p1-col {
    background-color: #0575e6;
  }
  &__p2-col {
    background-color: #ff5e62;
  }

  &__info-wrapper {
    display: flex;
    justify-content: space-between;
    &--jump-to {
      background: #272727;
      color: white;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0.5rem;
      margin: 0.5rem 1rem;
    }
  }
  &__li {
    list-style: none;
  }

  &__players-start-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__players-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    min-width: 310px;
  }

  &__player-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 0.5rem;
    width: 50%;
  }
  &__player-highlight {
    background: white;
    padding: 0.5rem;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: center;
    width: 80%;
    border-top: 2px solid #50c878;
    &--empty {
      padding: 0.5rem;
      border-radius: 5px;
      text-align: center;
    }
  }

  &__name-input {
    margin-top: 0.5rem;
    font-family: inherit;
    text-align: center;
    width: 60%;
    border: 0;
    border-bottom: 2px solid white;
    outline: 0;
    font-size: 1.3rem;
    // color: #fff;
    padding: 7px 0;

    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    width: 60%;
    transition: border-color 0.2s;

    &::placeholder {
      color: transparent;
    }

    // &:placeholder-shown {
    //   font-size: 1.3rem;
    //   cursor: text;
    //   top: 20px;
    // }

    &:focus {
      transition: 0.2s;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 4px;
      width: 60%;
      // font-size: 1rem;
      color: black;
      font-family: "Fellix-Bold";
      padding-bottom: 6px;
      font-weight: 700;
      border-bottom: solid 3px #118fe9;
    }
    /* reset input */

    &:required,
    &:invalid {
      box-shadow: none;
    }
  }

  &__input {
    width: 93px;
    text-align: center;
    font-size: 1.4rem;
    font-family: "Fellix-SemiBold";
    padding: 1rem 1rem;
    margin: 0.5rem 1rem;
    border-radius: 3px;

    background-color: rgba(255, 255, 255, 0.4);
    border: none;
    border-bottom: solid 2px white;

    &:focus {
      outline: none;
      font-family: "Fellix-Bold";
      border-bottom: solid 3px #118fe9;
    }
  }
  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
  }
  &__default-button {
    display: flex;
    background: #272727;
    border-radius: 5px;
    height: 37px;
    width: 270px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0.9rem 1rem;
  }
  &__label {
    color: #272727;
    font-size: 1.1rem;
    margin: 0 1rem;
  }

  &__label-2 {
    color: #272727;
    font-size: 1.1rem;
    margin: 0.8rem 1rem 0;
  }

  &__instructions {
    color: #272727;
    font-size: 1.2rem;
    margin: 1.2rem 1rem 0.5rem;
    text-align: center;
  }

  &__instructions-2 {
    margin: 0.5rem 1rem;
  }

  &__next-player {
    color: #333;
    font-size: 1.4rem;
    margin: 1rem 1rem;
  }

  &__confetti {
    position: absolute;
    width: 90%;
    min-height: 475px;
    z-index: 1;
  }
  @media only screen and (max-width: 500px) {
    &__players-wrapper {
      min-width: 310px;
    }
    &__player-highlight {
      width: 80%;
    }
    &__player-column {
      width: 50%;
    }

    &__name-input {
      width: 80%;
      &:focus {
        width: 80%;
      }
    }
    &__span {
      font-size: 2rem;
    }

    &__z-wrapper {
      height: 63vh;
      top: 50%;
    }

    &__z-wrapper-play {
      height: auto !important;
    }

    &__confetti {
      height: 50vh;
    }

    &__finish {
      height: 50vh;
    }

    &__board {
      height: 50vh;
      width: 97%;
      border: 0.7rem solid rgb(255, 255, 255);
      border-left: 0.3rem solid rgb(255, 255, 255);
      border-right: 0.3rem solid rgb(255, 255, 255);
    }
    &__squares {
      font-size: 2rem;
      max-height: 33vw;
    }
  }
}

[class|="confetti"] {
  position: absolute;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 150 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w * 0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l + "%");
    opacity: random() + 0.5;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i}
      unquote(4 + random() + "s")
      unquote(random() + "s")
      infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(15) + "%");
    }
  }
}

@-webkit-keyframes x-o {
  0% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(-130vh) rotate(600deg);
  }
}
@keyframes x-o {
  0% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(-130vh) rotate(600deg);
  }
}
