.associated-words {
  background-color: white;
  height: 100%;
  min-height: 100vh;
  padding: 2rem 10rem;

  &__words-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
  }

  &__new-tab {
    margin-bottom: 2rem;
  }

  &__similar-words {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--options {
      width: 33.33%;
      height: 20px;
      border: 1px solid #333;
      padding: 20px 20px;
      border-radius: 7px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      &:hover {
        cursor: pointer;
      }
      &--selected {
        border-bottom: none;
        height: 21px;
      }
    }
  }

  &__pdf-wrapper {
    border: 1px solid #333;
    border-top: none;
    border-radius: 4px;
    padding: 57px 10%;
    position: relative;
  }

  &__copyright {
    color: #333;
    margin-right: 4px;
    font-size: 1.4rem;
    font-family: "Fellix";
  }

  &__website {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    transform: translateX(-10%);
  }

  &__website-text {
    color: #ff7222;
    font-family: "Fellix-Bold";
    font-size: 1.7rem;
    margin-left: 10px;
  }

  &__download {
    position: absolute;
    right: 8px;
    top: 12px;
    width: 50px;
    text-align: center;

    &--img {
      width: 30px;
      cursor: pointer;
      &:hover {
        transform: translateY(2.3px);

        transition: 0.2s;
      }
    }
  }

  &__main-word {
    color: #333;
    font-size: 3rem;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    padding: 10px 30px;
    border-radius: 4px;
  }
  &__phoneme-container {
    border: 1px solid #333;
    padding: 12px 30px;
    margin: 10px 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 37px;
  }

  &__grapheme-container {
    border: 1px solid #333;
    padding: 12px 30px;
    margin: 10px 0px 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 37px;
  }

  &__bold {
    font-family: "Fellix-Bold";
    font-size: 1.2rem;
  }

  &__phoneme-name {
    min-width: 30%;
    padding-left: 20px;
    margin-left: 20px;
    color: #ff7222;
    font-family: "Fellix-Bold";
    font-size: 1.2rem;
    text-align: center;
  }

  &__grapheme-letters {
    min-width: 30%;
    padding-left: 20px;
    margin-left: 20px;
    color: #ff7222;
    font-family: "Fellix-Bold";
    font-size: 1.2rem;
    text-align: center;
  }

  &__grapheme {
    cursor: pointer;
    &--clicked {
      color: #ff7222;
      font-family: "Fellix-Bold";
    }
  }
  &__mapped-words-container {
    color: #333;
  }
  &__mapped-word {
    padding: 15px 0px;
    font-size: 2rem;
  }

  &__phoneme {
    &--relevant {
      color: #ff7222;
      font-family: "Fellix-Bold";
    }
  }
}
