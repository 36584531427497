.successful-subscription {
  color: #333;
  background-color: white;
  text-align: center;
  &__container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__heading {
    font-size: 3rem;
    margin: 20vh auto 2rem;
  }
  &__subheading {
    font-size: 1.5rem;

    margin: 0rem auto 1rem;
  }
  &__button {
    font-size: 1.5rem;
    margin: 2rem auto;
    padding: 2rem 5rem;
    width: 63%;
    color: white;
    background-color: #50c878;
    text-align: center;
    border-radius: 10px;
    transition: 0.2s;

    animation-name: wiggle;

    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: wiggle;
    -ms-animation-name: wiggle;
    -ms-animation-duration: 4s;

    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    &:hover {
      box-shadow: 0 5px 15px #50c878;
    }
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(2deg);
  }
  10% {
    -webkit-transform: rotate(-2deg);
  }
  20% {
    -webkit-transform: rotate(4deg);
  }
  30% {
    -webkit-transform: rotate(-1deg);
  }
  40% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(2deg);
  }
  10% {
    -ms-transform: rotate(-2deg);
  }
  20% {
    -ms-transform: rotate(4deg);
  }
  30% {
    -ms-transform: rotate(-1deg);
  }
  40% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(2deg);
  }
  10% {
    transform: rotate(-2deg);
  }
  20% {
    transform: rotate(4deg);
  }
  30% {
    transform: rotate(-1deg);
  }
  40% {
    transform: rotate(0deg);
  }
}
