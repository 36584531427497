.story-card-wrapper {
  margin: 0.34%;
}

.story-card {
  margin: 3%;
  width: 243px;
  position: relative;

  &--share {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 27px;
    width: 27px;
    font-size: 1rem;
    background-color: white;

    color: #3b96ff;
    text-align: center;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 7px #333;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 2px 7px black;
    }
  }

  &__reading-level-blurb {
    position: absolute;
    left: -100px;
    top: -57px;
    padding: 1rem 0.2rem;
    width: 8rem;
    font-size: 1rem;
    height: 20px;
    text-align: center;
    border: 2px solid #396afc;
    border-radius: 7px;
    background-color: white;
    color: #396afc;
    text-decoration: underline;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    transition: all 0.25s;
  }

  &--center {
    margin: auto;
  }
  &__image {
    background-size: cover;
    height: 430px;
    margin: 2% 0%;
    background-position: center;
    &--img {
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }
    img {
      width: 100%;

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &__content-wrapper {
    margin: 0;
    padding: 3% 5%;
    background-color: #fff;
    position: relative;
    height: 80px;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &--box-shadow {
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }
  }

  &__title {
    font-size: 1.1rem;
    width: 70%;
    position: absolute;
    left: 5%;
    top: 10%;
  }

  &__level-container {
    position: absolute;
    right: 5%;
    top: 10%;
    text-align: center;
  }

  &__level-text {
    width: 100%;
    display: block;
    font-size: 0.6rem;
    margin-bottom: 8px;
  }

  &__level-letter {
    color: #fff;
    font-weight: 700;
    border-radius: 4px;
    padding: 6px 9.5px;
  }

  &__tags {
    font-size: 0.8rem;
    position: absolute;
    right: 5%;
    bottom: 10%;
  }

  &__word-count {
    position: absolute;
    left: 5%;
    bottom: 10%;
    font-size: 0.8rem;
    font-weight: 500;
  }

  &__unlocked-ribbon {
    position: absolute;
    top: 0;
    font-size: 1.4rem;
    background-color: #396afc;
    color: white;
    text-align: center;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 10px 0 #cbdbdf;
    padding: 5% 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 5px 15px #396afc;
    }
  }
}
