// .StripeElement {
//   font-weight: 400;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   color: #495057;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   border: 1px solid #ced4da;
//   border-radius: 4px;
//   padding: 8px 12px 6px 12px;
//   width: 100%;
//   margin: 8px 0px 4px -1px;
//   height: 36px;
//   box-sizing: border-box;
//   transform: translateZ(0);
//   user-select: text;
//   -webkit-user-select: text;
// }

// .StripeElement--focus {
//   border: 1px solid #80bdff;
//   box-shadow: 0 0 3.2px #007bff;
//   outline: 0;
//   border-radius: 4px;
//   transition: box-shadow 0.15s;
// }

// .StripeElement--invalid {
//   border: 1px solid #dc3545;
//   padding: 8px 46px 6px 12px;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   background: transparent;
//   color: #495057;
//   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' fill='%23DC3545'/></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 99%;
//   background-position-y: 5px;
//   color: #495057;
// }

// .StripeElement--invalid.StripeElement--focus {
//   box-shadow: 0 0 3.2px #dc3545;
//   outline: 0;
//   border-radius: 4px;
//   transition: box-shadow 0.15s;
// }
// .StripeElement--complete {
//   border: 1px solid #28a745;
//   padding: 8px 46px 6px 12px;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   color: #495057;
//   background: transparent;
//   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' fill='%2328A745'/></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 99%;
//   background-position-y: 5px;
// }

// .StripeElement--complete.StripeElement--focus {
//   box-shadow: 0 0 3.2px #28a745;
//   outline: 0;
//   border-radius: 4px;
//   transition: box-shadow 0.15s;
// }

.modal {
  position: fixed;
  background: white;
  width: 80vw;
  left: 50%;
  top: 54%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 1001999;
  max-height: 80vh;
  box-shadow: 0 2px 10px 0 #9bbac2;
  overflow-y: auto;

  &__moonrock-banner-holder {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  &__moonrock-banner-img {
    width: 90%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 #54838f;
  }
  &__moonrock-text {
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 23px;
    font-size: 1.1rem;
  }

  &__row-email {
    display: flex;
    justify-content: space-around;
  }

  &__row-wrapper {
    display: flex;
    flex-direction: row;
  }
  &__share {
    &--text {
      transform: translateY(-10px);
      width: 80%;
      margin: auto;
      text-align: center;
    }
    &--input-clone {
      height: 50px;
      color: #1c1c1e;
      font-size: 1.6rem;
      padding: 0 1rem;
      border: 1px solid #d7dae0;
      border-radius: 0.6rem;
      &--animate {
        background-color: #4d7bd7;
      }
    }
    &--link {
      color: #4d7bd7;
      font-size: 1rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: #5d6169;
      height: 50px;
      overflow: hidden;
      &--animate {
        font-family: "Fellix-Bold";
        color: white;
      }
    }
    &--copy {
      margin: auto;
      margin-top: 10px;
      color: #4d7bd7;
      font-size: 1rem;
      font-weight: 700;
      width: 50px;
      cursor: pointer;
      white-space: nowrap;
      -webkit-appearance: none;
      appearance: none;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      i {
        font-size: 1rem;
        margin-left: 0.5rem;
      }
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      &:before,
      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }
      &:before {
        display: none;
        top: -75%;
        background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
          radial-gradient(circle, transparent 20%, #162947 20%, transparent 30%),
          radial-gradient(circle, #f18659 20%, transparent 20%),
          radial-gradient(circle, #4d7bd7 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, #dc659c 15%, transparent 20%),
          radial-gradient(circle, #dc659c 20%, transparent 20%),
          radial-gradient(circle, #dc659c 20%, transparent 20%),
          radial-gradient(circle, #4d7bd7 20%, transparent 20%),
          radial-gradient(circle, #e9b255 20%, transparent 20%);
        background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
          15% 15%, 10% 10%, 18% 18%;
      }
      &:after {
        display: none;
        bottom: -75%;
        background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
          radial-gradient(circle, #162947 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, #f18659 15%, transparent 20%),
          radial-gradient(circle, #dc659c 20%, transparent 20%),
          radial-gradient(circle, #dc659c 20%, transparent 20%),
          radial-gradient(circle, #4d7bd7 20%, transparent 20%),
          radial-gradient(circle, #e9b255 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
          20% 20%;
      }
      &--animate {
        &:before {
          display: block;
          animation: topBubbles ease-in-out 0.75s forwards;
        }
        &:after {
          display: block;
          animation: bottomBubbles ease-in-out 0.75s forwards;
        }
      }
    }
  }

  &__go-to-ttt {
    margin: 10px 0 0 10px;
    width: 170px;
    height: 160px;
    font-size: 1rem;
    padding: 2rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";

    background: linear-gradient(-45deg, #20ebae, #07b9ab, #0575e6, #0cebeb);
    background-size: 120% 120%;
    animation: gradient 7s ease infinite;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:hover {
      box-shadow: 0px 5px 15px #fff;
    }
  }

  &__go-to-memory-game {
    margin: 10px 0 0 10px;
    width: 170px;
    height: 160px;
    font-size: 1rem;
    padding: 2rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";

    background: linear-gradient(-45deg, #e73c7e, #ff4b2b);
    background-size: 120% 120%;
    animation: gradient 7s ease infinite;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:hover {
      box-shadow: 0px 5px 15px #fff;
    }
  }
  &__game-button-text {
    color: white;
    margin-top: 23px;
  }

  &__preview-story {
    &--page {
      box-shadow: 0 2px 10px 0 #9bbac2;
    }

    &--upgrade {
      background: linear-gradient(-45deg, #2948ff, #3b96ff, #516bff);
      background-size: 100% 100%;
      animation: gradient 10s ease infinite;

      padding: 20px 15px;
      cursor: pointer;
      text-align: center;

      margin: 20px 20px 25px;
      box-shadow: 0 5px 12px 0 rgba(56, 120, 226, 0.53);

      border-radius: 5px;
      color: white;
    }

    &--free-used {
      text-align: center;
      font-size: 1.2rem;
    }

    &--blue-text {
      color: #516bff;
      font-family: "Fellix-Bold";
      font-size: 1.4rem;
      padding-bottom: 10px;
      line-height: 1.4;
    }
    &--blue-text-small {
      color: #516bff;
      font-family: "Fellix-Bold";
      font-size: 1.2rem;
    }

    &--text-span {
      line-height: 3;
    }
    &--buttons {
      display: flex;
      flex-direction: row;
      width: 50%;
      margin: auto;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 23px;
    }
    &--confirm {
      width: 70px;
      text-align: center;
      cursor: pointer;
      padding: 20px 15px;
      border-radius: 4px;
      color: white;
      background: linear-gradient(-45deg, #2948ff, #3b96ff, #516bff);
      background-size: 200% 200%;
      animation: gradient 10s ease infinite;
      box-shadow: 0 5px 12px 0 rgba(56, 120, 226, 0.53);
    }
    &--no {
      width: 70px;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(to bottom right, #3b494d, #547a8b, #6c8287);
      box-shadow: 0 5px 12px 0 rgba(56, 120, 226, 0.53);
      background-size: 200% 200%;
      animation: gradient 10s ease infinite;
      padding: 20px 15px;
      border-radius: 4px;
      color: white;
    }
  }

  &__manage-card {
    &--add {
      cursor: pointer;
      background-color: #607d85;
      border-radius: 8px;
      padding: 8px 12px;
      margin: auto;
      margin-bottom: 10px;
      width: 210px;
      color: white;
      font-family: "Fellix-Bold";
      box-shadow: 0 2px 10px 0 #9bbac2;
    }

    &--back {
      cursor: pointer;
      border-radius: 8px;
      padding: 8px 12px;

      margin: 0px 0px 17px 0px;
      width: 80px;
      color: #607d85;
      text-decoration: underline;
    }

    &--pay-info {
      box-shadow: 0 2px 10px 0 #9bbac2;

      margin: 20px;
      padding: 10px;
      border-radius: 4px;

      transition: 0.2s;
    }
    &--button {
      border-radius: 4px;
      background: gray;
      margin-top: 10px;
      padding: 10px;
      cursor: pointer;
    }
    &--saved-cards {
      // display: flex;
      // flex-direction: row;
      // padding: 10px;
      // align-items: center;
      // justify-content: space-around;

      &--card {
        border-radius: 4px;
        padding: 10px;
        margin: 10px 0px;
      }
      &--selected {
        background: yellowgreen;
      }
      &--current {
        background: whitesmoke;
      }
    }

    &--monthly {
      box-shadow: 0 2px 10px 0 #9bbac2;
      margin: 20px;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s;
      height: 100px;
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 2px 10px 0 #6c8287;
      }
    }

    &--annual {
      box-shadow: 0 2px 10px 0 #9bbac2;
      margin: 20px;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s;
      height: 100px;
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 2px 10px 0 #6c8287;
      }
    }

    &--current {
      background-color: #50c878;
      color: #fff;
    }

    &--current-plan {
      position: absolute;
      left: 70px;
      background-color: white;
      color: #50c878;
      padding: 8px 12px;
      border-radius: 4px;
    }

    &--cancel {
      color: #a9a9a9;
      text-decoration: underline;
      cursor: pointer;
      width: 170px;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: center;
    }

    &--price-row {
      margin: 10px;
    }

    &--price {
      color: #50c878;
      font-size: 1.4rem;
    }

    &--price-white {
      color: #fff;
      font-size: 1.4rem;
    }

    &--original-price {
      text-decoration: line-through;
      font-size: 1.4rem;
      margin-right: 7px;
      color: #a9a9a9;
    }

    &--go-back {
      margin-bottom: 23px;
    }
    &--confirm-button {
      background-color: #fff;
      border: none;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #9bbac2;
    }
  }

  &__success {
    margin-bottom: 20px;
    color: #50c878;
  }

  &__error {
    margin-bottom: 20px;
    color: #cd5c5c;
  }

  &__child-profiles {
    display: flex;
    flex-direction: column;
  }

  &__option-child {
    cursor: pointer;
    padding: 17px 17px;
    background-color: #f8f8ff;
    box-shadow: 0px 0px 10px 0px #cbdbdf;
    margin: 10px 0px;
    border-radius: 5px;
    transition: 0.2s;

    &:hover {
      background-color: #e5f6e8;
      box-shadow: 0px 0px 10px 0px #50c878;

      padding: 23px 17px;
      transition: 0.1s;
      border-radius: 5px;
    }
  }

  &__change-reading-level {
    padding: 0rem 2rem;

    &--answer {
      border-radius: 5px;
      box-shadow: 0px 0px 10px 0px #cbdbdf;
      text-align: center;
      margin-bottom: 23px;
      padding: 10px;
    }

    &--answer-step {
      margin: 10px;
    }

    &--prompt {
      font-size: 1.4rem;
      transform: translateY(-10px);
      text-align: center;
    }
    &--how-to-test {
      color: #b993ff;
      text-decoration: underline;
      cursor: pointer;
      font-family: "Fellix-Bold";
      font-size: 1rem;
      margin: 4px 0 23px;
      text-align: center;
    }
    &--level {
      color: #fff;
      font-weight: 700;
      border-radius: 4px;
      padding: 6px 9.5px;
      margin: 6px;
      width: 50%;
      height: 30px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    &--level-row {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 7px 0px;
      padding: 7px 12px;
      border: 1px solid #d3d3d3;
      border-radius: 7px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      transition: all 0.25s;

      &:hover {
        box-shadow: 0 2px 10px 0 #50c878;
        background-color: #e5f6e8;
        padding: 17px 12px;
      }
    }
    &--selected {
      box-shadow: 0 2px 10px 0 #50c878;
      background-color: #e5f6e8;
      padding: 17px 12px;
    }
    &--levels-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &__stories-read {
    &--line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &--story {
      padding: 20px 0px;
      font-size: 1.3rem;
    }
    &--date {
      margin: auto 0;
    }
  }

  &__recently-learned {
    &--word-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 0px;
    }

    &--word {
      padding: 4px 7px;

      font-size: 1.5rem;
      font-weight: 700;

      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }

    &--date {
      padding: 4px 7px;
      font-size: 1.2rem;
      color: #50c878;
      align-self: center;
    }

    &--unlearn {
      cursor: pointer;
      padding: 4px 7px;
      border: 1.2px solid #333;
      border-radius: 8px;
      color: #333;
      font-size: 1rem;
      font-style: normal;
      align-self: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0 3px 7px #d3d3d3;
      }
    }
  }

  &__tracked-words {
    &--word {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5;
      text-align: left;
      margin-left: 0.2rem;
      background-color: #fff;
      padding: 3px 10px;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }
    &__back-home-arrow {
      width: 70px;
      padding: 20px;

      &:hover {
        padding-left: 5px;
        padding-right: 35px;
        transition: 0.2s;
      }

      &--img {
        width: 100%;
      }
    }
    &__tracking-container {
      background-color: rgba(233, 220, 214, 0.2);
      height: 90vh;
      overflow-y: auto;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      &--modalize {
        background-color: whitesmoke;
        min-width: 75%;
        width: 75%;
        height: 42.3vh;
        overflow-y: scroll;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        bottom: 1%;
        border-radius: 10px;
        position: fixed;
      }
      &--title {
        background-color: #f4ede3;
        margin: auto;
        padding: 2% 1%;
        font-size: 1.2rem;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        display: flex;
        flex-direction: row;

        &--modalize {
          background-color: whitesmoke;
          text-align: left;
          width: 61%;
          font-size: 1.2rem;
          box-shadow: 0 2px 10px 0 #cbdbdf;
          display: flex;
          flex-direction: row;
          padding: 4% 7%;
          bottom: 43%;

          border-radius: 10px;
          position: fixed;
        }
      }

      &--number {
        font-size: 2rem;
        font-weight: 700;
        color: #e5532a;
        display: inline-block;
        margin-right: 5px;
        margin-left: 2.5rem;
      }
    }

    &--tracking-section {
      padding: 20px 0px;
    }
    &__tracking-section {
      display: flex;
      flex-direction: row;
      margin: auto;

      border-top: 1px solid #333;

      &--times-clicked {
        min-width: 23vw;
        margin-left: 20px;
      }

      &--phonic-word-container {
        display: flex;
        flex-direction: row;
        min-width: 30vw;
        padding-top: 10px;
      }

      &--phonic-part-container {
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      &--remove-word {
        border: none;
        background-color: transparent;
        font-weight: 800;
        margin-left: auto;
      }
      &--minus-icon {
        padding: 5px 7px;
        border: 3px solid #e5532a;
        border-radius: 50%;
        color: #e5532a;
        font-size: 1.3rem;
      }
      &--save {
        background-color: white;
        border: none;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        margin-left: 30px;
      }
      &--save-success {
        font-size: 1rem;
        margin-left: 30px;
        margin-top: 7px;
      }

      &-chevron {
        position: fixed;
        bottom: 0;
        font-size: 200%;
      }
      &--phonic-click {
        width: 2.5rem;
        height: 2rem;
        border: 1px solid black;
        &-small {
          width: 2rem;
          height: 1.7rem;
          border: 0.5px solid black;
        }
      }
    }
    &__phonic-part {
      &--first {
        color: #f9a557;
        font-family: "Fellix-Bold";
      }

      &--second {
        color: #ff7222;
        font-family: "Fellix-Bold";
      }

      &--middle {
        color: #e35072;
        font-family: "Fellix-Bold";
      }

      &--penultimate {
        color: #b466d2;
        font-family: "Fellix-Bold";
      }

      &--last {
        color: #3eb8cb;
        font-family: "Fellix-Bold";
      }
    }

    &__phonic-click {
      &--first {
        background-color: #f9a557;
      }

      &--second {
        background-color: #ff7222;
      }

      &--middle {
        background-color: #e35072;
      }

      &--penultimate {
        background-color: #b466d2;
      }

      &--last {
        background-color: #3eb8cb;
      }
    }
  }

  &__payment-options {
    text-align: center;
  }

  &__stripe {
    &--go-back {
      cursor: pointer;
      color: #808080;
      width: 20%;
      margin: auto;
      text-align: center;
    }

    &--selection-info {
      font-size: 1.2rem;
      margin: auto;
      text-align: center;
    }

    &--image-wrapper {
      background-color: #b994ff;
      width: 90px;
      height: 90px;
      text-align: center;
      border-radius: 7px;
      position: relative;
      margin: auto;
      margin-top: 17px;
      margin-bottom: 0px;
      padding: 5% 0;
      width: 60%;
      max-width: 500px;

      > img {
        width: 100px;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &--secure-payment {
      display: flex;
      flex-direction: row;
      margin: auto;
      width: 270px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 40px;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    &--lock-icon {
      margin-left: 7px;
    }

    &--powered-by {
      margin: auto;
      text-align: center;
      width: 100px;
    }
    &--payment-form {
      padding: 5%;
      padding-bottom: 0;
      margin: auto;
      position: relative;
      width: 90%;
      max-width: 500px;
      transition-property: opacity, transform;
      transition-duration: 0.35s;
      flex-wrap: nowrap;
      & label {
        width: 15%;
        min-width: 70px;
        padding: 11px 0;
        color: #c4f0ff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & input {
        width: 100%;
        padding: 11px 15px 11px 0;
        color: #fff;
        font-family: "Fellix" !important;
        font-size: 1rem;
        letter-spacing: 0.5px;
        background-color: transparent;
        appearance: none;
        outline: none;
        border-style: none;

        &::placeholder {
          color: #c4f0ff;
          font-family: "Fellix" !important;
          font-size: 1rem;
        }
      }
      & fieldset {
        margin: 0 15px 20px;
        padding: 0;
        border-style: none;
        background-color: #7795f8;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 #9bbac2, inset 0 1px 0 #829fff;
      }
      & .row {
        display: flex;
        -ms-flex-align: center;
        align-items: flex-end;
        margin-left: 15px;
        justify-content: space-between;
        align-content: stretch;
      }
      & .row + .row {
        border-top: 1px solid #819efc;
      }

      & .StripeElement {
        width: 100%;
        padding: 11px 15px 11px 0;
      }
    }
    &--payment-button {
      font-size: 1rem;
      border: none;
      border-radius: 4px;
      font-family: "Fellix-Bold";
      color: white;
      background-color: #50c878;
      box-shadow: 0 2px 10px 0 #9bbac2, inset 0 1px 0 #50c878;
      cursor: pointer;
      display: block;
      width: calc(100% - 30px);
      height: 40px;
      margin: 20px 15px 30px;
      // animation-name: wiggleSlow;

      // animation-duration: 8s;
      // animation-delay: 4s;
      // animation-iteration-count: infinite;
      // animation-timing-function: ease-in-out;
      // -webkit-animation-name: wiggleSlow;
      // -ms-animation-name: wiggleSlow;
      // -ms-animation-duration: 8s;
      // -webkit-animation-duration: 8s;

      // -ms-animation-delay: 4s;
      // -webkit-animation-delay: 4s;

      // -webkit-animation-iteration-count: infinite;
      // -ms-animation-iteration-count: infinite;
      // -webkit-animation-timing-function: ease-in-out;
      // -ms-animation-timing-function: ease-in-out;
    }
  }

  &__close {
    cursor: pointer;
    align-self: flex-end;
    font-family: "Fellix-SemiBold";
    font-size: 1.3rem;
    padding: 10px;
    z-index: 1;
    &--white {
      color: white;
      background-color: #5854a1;
      border-radius: 10%;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }
  &__instead {
    &--link {
      cursor: pointer;
      color: #5854a1;
    }
  }

  &__password {
    &--link {
      cursor: pointer;
      color: #807f7f;
      text-decoration: underline;
      font-size: 1rem;
      margin-bottom: 20px;
    }
  }

  &__prompt {
    font-size: 1.2rem;
    transform: translateY(-10px);
    text-align: center;
  }

  &__forgot-prompt {
    margin-bottom: 10px;
  }

  &__register-title {
    font-size: 1.4rem;
    transform: translateY(-10px);
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
  }

  &__button {
    border: 1px solid #c5c5c5;
    background-color: white;
    font-size: 1.2rem;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    padding: 40px;
    width: 100%;
    transition: all 0.25s;
    background-size: contain;
    width: 286px;
    height: 500px;

    &--parent {
      background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/parent-button.png");
      &:hover {
        transform: translateY(-5px);
        // box-shadow: 5px 5px 5px #2f30bd;
      }
    }
    &--educator {
      background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/educator-button.png");

      &:hover {
        transform: translateY(-5px);
        // box-shadow: 5px 5px 5px #50caa8;
      }
    }
  }

  &__input {
    padding: 1rem 0.5rem;
    font-size: 16px;
    font-family: "Fellix-SemiBold" !important;
    margin-bottom: 0.5rem;
    border: 1.5px solid #d3d3d3;
    border-radius: 4px;
    resize: none;
  }

  &__submit {
    appearance: unset;
    user-select: unset;
    white-space: unset;
    align-items: unset;
    text-align: unset;
    cursor: unset;
    color: unset;
    background-color: unset;
    box-sizing: unset;
    padding: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;

    padding: 1rem 0.5rem !important;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    color: white;
    background-color: #396afc;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    &:hover {
      box-shadow: 0px 5px 15px #396afc;
    }
  }

  &__user-role {
    font-size: 1.4rem;
    margin-top: 230px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    &__user-role {
      margin-top: 140px;
    }
    &__facebook-login {
      width: 12rem !important;
    }
  }

  &__you-quote {
    color: #5854a1;
    font-size: 1.2rem;
  }

  &__social-sign-in-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 2rem 0rem;
  }

  &__facebook-button-fix {
    height: 3rem;
    border: none;
    border-radius: 5px;
    background-color: #3c5997;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    width: 80%;
    box-shadow: 0 2px 10px 0 #9bbac2;
  }

  &__facebook-login {
    width: 16rem;
    border: none;
    border-radius: 5px;
    background-color: #3c5997;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      font-size: 1rem;
    }
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 5px 15px #3c5997;
    }
  }

  &__google-login {
    padding: 0.25rem 0.5rem;
    height: 3rem;
    width: 12rem;
    border: none;
    border-radius: 5px;
    background-color: #e3522a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    width: 80%;
    box-shadow: 0 2px 10px 0 #9bbac2;

    i {
      margin-right: 0.35rem;
      margin-left: 0.25rem;
      font-size: 1rem;
    }
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 5px 15px #e3522a;
    }
  }
  &__email-button {
    padding: 0.25rem 0.5rem;
    height: 3rem;
    width: 12rem;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__email-button {
    margin: 1rem auto;
    padding: 0.25rem 0.5rem;
    height: 3rem;
    width: 80%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 2px 10px 0 #d3d3d3;
  }

  &__instead--link {
    font-family: "Fellix-Bold";
    font-size: 1.1rem;
    margin-left: 5px;
  }

  &__stripe-h2 {
    margin-top: 223px;
    margin-bottom: 12px;
  }

  &__premium-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3.4rem;
  }

  &__premium-card {
    box-shadow: 0 2px 10px 0 #9bbac2;
    border-radius: 7px;
    padding: 1rem;
    margin: 1rem;
    width: 230px;
    height: 170px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.37);

    &:hover {
      cursor: pointer;
    }

    &--heading {
      font-size: 1.2rem;
      z-index: 1;
      color: #333;
      margin-top: 3.5px;
    }

    &--price {
      font-size: 2rem;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.93);
    }

    &--original-price {
      text-decoration: line-through;
    }

    &--price-row {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      justify-content: center;
      margin-top: 17px;
    }

    &--annual-banner {
      box-shadow: 0 2px 10px 0 #9bbac2;
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      color: #2948ff;
      width: 100%;
      padding: 1rem 0rem;
      border-radius: 7px 7px 0 0;
      text-align: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 15px #2948ff;
      }
    }

    &--monthly {
      background-color: #50c878;
      color: white;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 15px #50c878;
      }
    }

    &--annual {
      background-color: #2948ff;
      color: white;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 15px #2948ff;
      }
    }
  }

  &__main-wrapper {
    text-align: center;
  }

  &__modal-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__welcome-to {
    width: 90%;
    max-width: 400px;
    padding: 2rem 1rem 0.5rem;
  }

  &__ribbon-title {
    position: absolute;
    top: 8%;
    left: 15%;
    width: 70%;
  }

  &__ribbon-underline {
    position: absolute;
    bottom: 25%;
    left: 24.8%;
    width: 50%;
  }

  &__ribbon-flag {
    position: absolute;
    top: -30%;
    left: 9%;
    width: 54%;
  }

  @media only screen and (max-width: 500px) {
    width: 87vw;
    padding: 1rem;

    &__preview-story {
      &--buttons {
        width: 90%;
      }
    }

    &__button {
      height: 283px;
    }

    &__change-reading-level {
      padding: 0rem 0rem;
    }
    &__stripe-h2 {
      margin-top: 123px;
      margin-bottom: 0px;
    }
    &__premium-card {
      padding: 0.5rem 1rem;
      margin: 0rem;
      margin-bottom: 0.5rem;

      &:first-child {
        margin-right: 0.35rem;
        margin-left: -0.2rem;
      }

      &:last-child {
        margin-left: 0.35rem;
        margin-right: -0.2rem;
      }

      &--heading {
        font-size: 1.2rem;
        margin-top: 8%;
      }

      &--price {
        font-size: 1.7rem;
      }
    }

    &__ribbon-title {
      position: absolute;
      top: 10%;
      left: 7%;
      width: 90%;
    }

    &__ribbon-underline {
      position: absolute;
      bottom: 25%;
      left: 14.9%;
      width: 70%;
    }

    &__ribbon-flag {
      position: absolute;
      top: -21%;
      left: 9%;
      width: 54%;
    }
  }
}

@-webkit-keyframes wiggleSlow {
  0% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(-1deg);
  }
  10% {
    -webkit-transform: rotate(2deg);
  }
  15% {
    -webkit-transform: rotate(-0.5deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggleSlow {
  0% {
    -ms-transform: rotate(1deg);
  }
  5% {
    -ms-transform: rotate(-1deg);
  }
  10% {
    -ms-transform: rotate(2deg);
  }
  15% {
    -ms-transform: rotate(-0.5deg);
  }
  20% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggleSlow {
  0% {
    transform: rotate(1deg);
  }
  5% {
    transform: rotate(-1deg);
  }
  10% {
    transform: rotate(2deg);
  }
  15% {
    transform: rotate(-0.5deg);
  }
  20% {
    transform: rotate(0deg);
  }
}
@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
