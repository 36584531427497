.assignment-header {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 200px;
  background: aquamarine;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
