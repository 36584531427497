.teacher-portal {
  &__classroom-details-container {
    background: whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
  }
  &__classroom-details-item {
    background: lightgray;
    border-radius: 5px;
    padding: 10px 10px;
    width: 20%;
    margin: 0 10%;
    text-align: center;
  }

  &__quick-assign {
    background: white;
    border-radius: 4px;
    padding: 5px 3px;
  }

  &__class-code {
    &--value {
      background: white;
      border-radius: 4px;
      padding: 3px 5px;
    }
  }

  &__students-length {
    &--value {
      font-size: 1.5rem;
    }
  }

  &__classroom-name {
    cursor: pointer;
    background: whitesmoke;
    padding: 10px 10px;
    width: 100%;
    border: 1px solid black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    &:first-of-type {
      border-left: none;
    }
    &:last-of-type {
      border-right: none;
    }
    &--active {
      border-bottom: none;
    }
  }

  &__classroom-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    background: whitesmoke;
    &--cell {
      text-align: center;
      padding: 10px 10px;
    }
  }

  &__student-row {
    display: flex;
    flex-direction: row;
  }

  &__classroom-name-container {
    display: flex;
    flex-direction: row;
  }
}
