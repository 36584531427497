html {
  overscroll-behavior-x: none;
}
body {
  overscroll-behavior-x: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  // box-shadow: 0 2px 5px 0 #96bcfe;
  border-radius: 10px;
  margin-left: 1.9rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #add3ff;
  // box-shadow: 0 2px 5px 0 #96bcfe;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8bc1ff;
}

.reading-portal {
  &__books-filter-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    max-width: 600px;
  }

  &__books-section-title {
    color: white;
    font-size: 3rem;
    font-family: "Fellix-Bold";
    transform: translateY(-40px);

    padding: 0px 0px 0px 1rem;
  }

  &__user-details-row {
    display: flex;
    flex-direction: row;
    padding: 37px;
    padding-left: 1rem;
    overflow-x: auto;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
  }

  &__arrow-down {
    margin-left: 7px;
  }

  &__reading-button-text {
    position: absolute;
    bottom: 20px;
    z-index: 1;

    color: white;
  }

  &__reading-button-img {
    width: 70px;
    margin: auto;
    transform: translateY(-10px);
  }

  &__moonrok-button-img {
    width: 90px;
    margin: auto;
    transform: translateY(-10px);
  }

  //   &__reading-button-img-holder {
  // }
  &__referrals {
    min-width: 290px;
    height: 280px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 7px;
    margin: 0rem 0 0 2rem;
    background-image: url("https://study-portal.s3.us-east-2.amazonaws.com/referrals-bg.png");
    background-size: cover;
    background-position-x: -100px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      box-shadow: 0px 15px 25px rgba(0, 0, 0, 1);
      transform: translateY(-7px);
    }
  }

  &__referrals--text-dark {
    color: #162947;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-family: "DM Sans", sans-serif;
    margin-bottom: 10px;
  }

  &__referrals--text-white {
    color: #fff;
    font-size: 1.6rem;
    width: 80%;
  }

  &__referrals--text-conditions {
    color: #fff;
    margin-top: 10px;
    font-size: 0.7rem;
  }

  &__go-to-stories {
    margin: 4rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #ff9966, #ff5e62, #ffb347, #f7971e);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 5px 15px #fff;
    }
  }

  &__go-to-worksheets {
    margin: 4rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #ff416c, #e73c7e, #ff4b2b, #009fff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 5px 15px #fff;
    }
  }

  &__go-to-games {
    margin: 4rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #04be86, #06cc80, #0575e6, #01b3b3);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 5px 15px #fff;
    }
  }

  &__go-to-MoonRok {
    margin: 4rem 0 0 2rem;
    min-width: 160px;
    height: 180px;
    padding: 0rem 1rem;
    border: none;
    border-radius: 4px;
    font-family: "Fellix-Bold";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(-45deg, #c840f1, #4946ff, #6427f3, #4759ff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    &:hover {
      box-shadow: 0px 5px 15px #fff;
    }
  }

  &__filters-container {
    display: flex;
    flex-direction: column;
  }

  &__filter {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    margin-bottom: 2rem;
    width: 300px;
    padding: 0px 12px;

    color: white;

    &--button {
      margin: 0px 12px;
      cursor: pointer;
      &:hover {
        color: rgba(255, 255, 255, 0.4);
      }
      &--selected {
        margin: 0px 12px;

        text-decoration: underline;
      }
    }
  }

  &__search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    margin-bottom: 2rem;
    width: 250px;
    background-color: white;
    font-family: "Fellix" !important;
    border-radius: 4px;
    padding: 0px 12px;
    justify-content: flex-start;

    &--input {
      background-color: white;
      border: none;
      margin-left: 7px;
      padding: 10px 8px;
      font-family: "Fellix" !important;
      font-size: 1.2rem;

      &:focus {
        outline: none;
        box-shadow: 0 5px 15px rgba(255, 255, 255, 0.4);
        padding: 12px 8px;

        font-family: "Fellix" !important;
      }
    }
  }

  &__heading-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 12vh;

    text-align: center;
    width: 70%;
  }

  // &__footer-before {
  //   height: 10px;
  //   margin-top: 120px;
  //   width: 100%;
  //   background: #fff;
  //   background: -webkit-linear-gradient(
  //     to bottom,
  //     #000000,
  //     #ffffff
  //   ); /* Chrome 10-25, Safari 5.1-6 */
  //   background: linear-gradient(
  //     to bottom,
  //     #000000,
  //     #000000,
  //     #ffffff
  //   ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  // }

  &__home-video-container {
    position: absolute;
    left: 3rem;
    bottom: 11rem;
    z-index: 1;
  }

  // &__heading-1 {
  //   color: whitesmoke;
  //   font-size: 3rem;
  //   width: 100%;
  //   background-color: rgba(0, 0, 0, 0.9);
  //   padding: 20px 40px;
  //   border-bottom: 1px solid white;
  // }

  // &__heading-2 {
  //   color: whitesmoke;
  //   font-size: 3rem;
  //   width: 77%;
  //   background-color: rgba(0, 0, 0, 0.9);
  //   padding: 10px 40px 10px;
  //   border-bottom: 1px solid white;
  // }

  // &__subheading {
  //   color: whitesmoke;
  //   width: 57%;
  //   font-size: 1.2rem;
  //   background-color: rgba(0, 0, 0, 0.9);
  //   padding: 20px 40px 20px;
  //   border-bottom: 1px solid white;
  // }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
  }
  &__headline {
    position: absolute;
    width: 45%;
    left: 22vw;
    top: 15vh;
    transition: all 0.2s ease-in-out;
    animation-name: float;

    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: float;
    -ms-animation-name: float;
    -ms-animation-duration: 10s;

    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
  }
  &__subheadline {
    position: absolute;
    width: 20%;
    right: 12vw;
    top: 58vh;
    transition: all 0.2s ease-in-out;
    animation-name: float;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: float;
    -ms-animation-name: float;
    -ms-animation-duration: 5s;

    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
  }
  &__rows {
    margin: 2.5rem 2rem 0.5rem;
    &--signed-out {
      margin: 32.5rem 2rem 0.5rem;
    }
  }
  &__row {
    margin-bottom: 2.3rem;
  }
  &__row-stories {
    display: flex;
    flex-direction: row;
    overflow: auto;
    &--no-scroll {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    &--scroll-left {
      position: absolute;
      left: 2rem;
      margin-top: 0.67rem;
      height: 529.4px;
      width: 70px;
      z-index: 9999;
      border: none;
      color: white;
      font-size: 2rem;
      background-color: transparent !important;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7), transparent);
    }
    &--scroll-right {
      position: absolute;
      right: 2rem;
      margin-top: 0.67rem;
      height: 529.4px;
      width: 70px;
      z-index: 9999;
      border: none;
      color: white;
      font-size: 2rem;
      background-color: transparent !important;
      background-image: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0.7),
        transparent
      );
    }
  }

  &__row-title {
    // background-color: #fff;
    color: white;
    font-weight: 700;
    padding: 4px 8px;
    margin-left: 1rem;
    border-radius: 4px;
    font-size: 1.4rem;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.3);
  }

  &__subscribe-placeholder {
    height: 4.8rem;
  }

  &__subscribe-banner {
    z-index: 99999;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    width: 100%;
    background: linear-gradient(-45deg, #6967f7, #3b96ff, #4c83f1, #6798f7);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
    color: white;
    padding: 0.5rem 0;
    box-shadow: 0 4px 5px -2px black;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 5px 15px black;
    }
  }

  &__subscribe-prompt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 1rem;
    font-size: 1.7rem;

    img {
      width: 33px;
      height: 29px;
      margin-right: 0.25rem;
    }
  }

  &__subscribe-button {
    margin: 0.5rem 3rem 0.5rem 0rem;
    background-color: white;
    border: none;
    border-radius: 50px;
    font-size: 1.2rem;
    color: #004488;
    transition: all 0.2s ease-in-out;
    animation-name: wiggle;

    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: wiggle;
    -ms-animation-name: wiggle;
    -ms-animation-duration: 4s;

    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    &:hover {
      box-shadow: 0px 3px 7px white;
    }
  }

  @media only screen and (max-width: 1120px) {
    // &__heading-1 {
    //   font-size: 2rem;
    //   padding: 15px 30px;
    // }

    &__rows {
      margin-top: 2.5rem;
      &--signed-out {
        margin-top: 15.5rem;
      }
    }

    &__headline {
      position: absolute;
      width: 45%;
      left: 23vw;
      top: 9vh;
    }

    &__subheadline {
      position: absolute;
      width: 25%;
      right: 9vw;
      top: 26vh;
    }
  }

  @media only screen and (max-width: 500px) {
    &__books-filter-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    &__books-section-title {
      color: white;
      font-size: 3rem;
      font-family: "Fellix-Bold";
      transform: translateY(-20px);
      text-align: center;
    }
    &__referrals {
      width: 90%;
      min-width: 160px;
      max-width: 290px;
      height: 140px;
      box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.8);
      color: white;
      border-radius: 4px;
      margin: auto;

      background-position-x: -100px;
      padding: 30px;
    }

    &__referrals--text-dark {
      color: #162947;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.2rem;
      font-family: "DM Sans", sans-serif;
      margin-bottom: 10px;
    }

    &__referrals--text-white {
      color: #fff;
      font-size: 1.3rem;
      width: 80%;
    }
    &__row-stories {
      &--scroll-left {
        margin-top: 0.52rem;
        left: 0.96rem;
      }
      &--scroll-right {
        margin-top: 0.52rem;
        right: 0.96rem;
      }
    }

    &__buttons-container {
      overflow-x: scroll;
      padding: 20px 17px;
    }

    &__search-container {
      margin-left: auto;
      margin-right: auto;
    }
    &__go-to-stories {
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
      width: 95%;
    }

    &__go-to-worksheets {
      margin-top: 2rem;
    }

    &__go-to-games {
      margin-top: 2rem;
    }

    &__go-to-MoonRok {
      margin-top: 2rem;
    }

    &__heading-container {
      margin-top: 100px;
      margin-bottom: 0vh;
      width: 100%;
    }

    &__headline {
      position: absolute;
      width: 98%;
      left: 2vw;
      top: 100px;
      transform: rotate(-7deg);

      & img {
        width: 100%;
      }
    }

    &__subheadline {
      position: absolute;
      width: 40%;
      right: 3vw;
      top: 175px;
      transform: rotate(12deg);
    }

    &__row-title {
      font-size: 1.4rem;
      padding: 4px 5px;
      margin-left: 0.59rem;
    }

    &__home-video-container {
      position: absolute;
      left: 5px;
      top: 2.5rem;
      z-index: 1;
    }

    &__rows {
      margin: 2.5rem 1rem 1rem;
      &--signed-out {
        margin: 12rem 1rem 1rem;
      }
    }
    &__row {
      margin-bottom: 2rem;
    }

    &__subscribe-prompt {
      margin: 0.5rem 2.3rem;
      font-size: 1.2rem;

      img {
        margin-left: 0.25rem;
        margin-top: 0.45rem;
        margin-bottom: 3px;
      }
    }
    &__subscribe-button {
      margin: 0.2rem 2.3rem 0.2rem 0rem;
      background-color: white;
      border: none;
      border-radius: 25px;
      font-size: 1rem;
    }
    &__subscribe-placeholder {
      height: 4rem;
    }
  }

  &__parent-profile-container {
    height: 100vh;
  }

  &__parent-profile {
    margin: 0.5rem 1.5rem;
    background-color: #b3c0ca;
    border-radius: 5px;
    width: 75%;
  }

  &__child-data {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding: 10px;

    &--name {
      text-align: center;
    }

    &--stories-read {
      text-align: center;
    }

    &--tracked-words {
      text-align: center;
    }
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(2deg);
  }
  10% {
    -webkit-transform: rotate(-2deg);
  }
  20% {
    -webkit-transform: rotate(4deg);
  }
  30% {
    -webkit-transform: rotate(-1deg);
  }
  40% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(2deg);
  }
  10% {
    -ms-transform: rotate(-2deg);
  }
  20% {
    -ms-transform: rotate(4deg);
  }
  30% {
    -ms-transform: rotate(-1deg);
  }
  40% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(2deg);
  }
  10% {
    transform: rotate(-2deg);
  }
  20% {
    transform: rotate(4deg);
  }
  30% {
    transform: rotate(-1deg);
  }
  40% {
    transform: rotate(0deg);
  }
}

@keyframes float {
  0% {
    transform: rotate(0deg) translate(0px, 0px);
  }
  50% {
    transform: rotate(-2deg) translate(-5px, -10px) scale(0.9);
  }

  100% {
    transform: rotate(0deg) translate(0px, 0px);
  }
}

@-ms-keyframes float {
  0% {
    -ms-transform: rotate(0deg) translate(0px, 0px);
  }
  50% {
    -ms-transform: rotate(-2deg) translate(-5px, -10px) scale(0.9);
  }
  100% {
    -ms-transform: rotate(0deg) translate(0px, 0px);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: rotate(0deg) translate(0px, 0px);
  }
  50% {
    -webkit-transform: rotate(-2deg) translate(-5px, -10px) scale(0.9);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(0px, 0px);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
