@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500italic);
@import "../../../node_modules/compass-mixins/lib/compass/css3";

@mixin m($val) {
  margin-top: $val;
  margin-bottom: $val;
} // 'margin'
@mixin p($val) {
  padding-top: $val;
  padding-bottom: $val;
} // 'margin sides'
@mixin ms($val) {
  margin-left: $val;
  margin-right: $val;
} // 'padding'
@mixin ps($val) {
  padding-left: $val;
  padding-right: $val;
} // 'padding sides'
@mixin dim($width, $height) {
  width: $width;
  height: $height;
} // 'dimensions'

@mixin center($width, $height) {
  position: absolute;
  left: 44%;
  bottom: 5%;
  width: 150px;
  height: 150px;
  margin-left: -($width/2);
  margin-top: -($height/2);
}

@mixin texthide {
  line-height: 0;
  font-size: 0;
  color: transparent;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation(
  $name,
  $duration: 1s,
  $delay: 0s,
  $iterations: 1,
  $direction: normal,
  $timing: ease,
  $fillmode: forwards
) {
  -webkit-animation: $name $duration $delay $iterations $direction $timing
    $fillmode;
  -moz-animation: $name $duration $delay $iterations $direction $timing
    $fillmode;
  -o-animation: $name $duration $delay $iterations $direction $timing $fillmode;
  animation: $name $duration $delay $iterations $direction $timing $fillmode;
}

@include keyframes(pulse) {
  0% {
    opacity: 0;
    background-position: center top;
    @include background-size(0 auto);
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    @include background-size(75% auto);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    background-position: center bottom;
    @include background-size(0 auto);
  }
}

$birdColor: #11e7d7;
$birdColor2: #31bfae;
$birdColor3: #01c7be;
$feetColor: #f7ad5d;
$beakColor: #f7ad5d;
$beakColor2: #eb7f2d;

// Storyboard Timeline
$timeline: (
  "begin": 0s,
  "eye-p0-1": 0.4s,
  "eye-p1-1": 0.4s,
  "eye-p4-1": 1.8s,
  "eye-p3-1": 0.4s,
  "eye-p4-2": 1s,
  "eye-p2-1": 0.1s,
  "end": 0.4s,
);

// $totaltime:						Total of all timecodes in $timeline
// $timelineAccumulated:	Accumulated timeline map based on $timeline
$totaltime: 0s;
$timelineAccumulated: ();
@each $key, $time in $timeline {
  $totaltime: $totaltime + $time;
  $timelineAccumulated: map-merge(
    $timelineAccumulated,
    (
      $key: $totaltime,
    )
  );
}

// Timecode to Percent function
// Params:
// 	$key:			key/position in timeline
// 	$offset:	optional offset to add to time from timeline (use negative number to subtract)
// Return: 		Percentage value of keyposition in timeline.
@function tp($key, $offset: 0s) {
  @return (map-get($timelineAccumulated, $key) + $offset) / $totaltime * 100%;
}

@keyframes eyes {
  #{tp('begin')},
  #{tp('eye-p0-1')},
  #{tp('end')} {
    top: -50px;
  }
  #{tp('eye-p1-1')} {
    top: -45px;
  }
  #{tp('eye-p2-1')} {
    top: -20px;
  }
  #{tp('eye-p4-1')},
  #{tp('eye-p4-2')} {
    top: -15px;
  }
  #{tp('eye-p3-1')} {
    top: -25px;
  }
}
@keyframes wings {
  #{tp('begin')},
  #{tp('eye-p4-2')},
  #{tp('end')} {
    top: 70px;
  }
  #{tp('eye-p2-1')} {
    top: 60px;
  }
}

@keyframes feet {
  #{tp('begin')},
  #{tp('eye-p4-2')},
  #{tp('end')} {
    top: -2px;
  }
  #{tp('eye-p2-1')} {
    top: 0px;
  }
}
@keyframes cloud {
  0% {
    left: -140px;
  }
  100% {
    left: 280px;
  }
}

.story {
  text-align: center;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 82rem;
  margin: auto;
  max-height: 100vh;

  &__prev-page {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50px;
    z-index: 1;
  }

  &__next-page {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    z-index: 1;
  }

  &__edit-button {
    background-color: #107e94;
    color: white;
    border-radius: 4px;
    padding: 30px 20px;
    margin: auto;
    position: relative;
    cursor: pointer;
  }

  &__fullscreen-icon {
    margin: 0 10px 0 -10px;
    font-size: 1.5rem;
  }

  &__line {
    z-index: 9;
    &--empty {
      max-height: 1rem;
      pointer-events: none;
    }
    &--highlight {
      background: red;
    }
    &--word {
      color: black;
      animation: grow 5s infinite;
    }
    &--word-highlight {
      color: green;
    }
  }

  &__questions-text {
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  }

  &__outside {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  &__loading {
    padding: 10vh 0;
    margin: auto;
    font-size: 3rem;
    background: #000000; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to bottom right,
      #00d2ff,
      #3a7bd5
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to bottom right,
      #00d2ff,
      #3a7bd5
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 80vh;
    text-align: center;

    &--text {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--lds-ellipsis {
      position: relative;
      width: 80px;
      height: 80px;

      & div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      & div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      & div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      & div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      & div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
    }

    &--eye {
      position: absolute;
      z-index: 1;
      overflow: hidden;
      width: 56px;
      height: 56px;
      top: 28px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid $birdColor3;
      box-shadow: inset 0 0 0 1px $birdColor3;
      &:before,
      &:after {
        position: absolute;
        content: "";
      }
      &:before {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #000;
        top: 30px;
      }
      &:after {
        width: 200px;
        height: 200px;
        background: radial-gradient(
          ellipse at center,
          rgba(109, 0, 25, 0) 0%,
          rgba(130, 1, 31, 0) 35%,
          $birdColor3 36%,
          $birdColor2 100%
        );
        animation: eyes $totaltime linear infinite;
      }
    }
    &--left {
      left: 15px;
      &:before {
        left: 20px;
      }
      &:after {
        left: -60px;
        top: -45px;
      }
    }
    &--right {
      right: 15px;
      &:before {
        right: 20px;
      }
      &:after {
        right: -60px;
        top: -45px;
      }
    }
    &--beak {
      position: absolute;
      z-index: 1;
      width: 41px;
      height: 55px;
      top: 70px;
      left: 67px;
      border-radius: 50%;
      background-color: $birdColor2;
      &:before {
        position: absolute;
        content: "";
        width: inherit;
        height: inherit;
        top: 2px;
        border-radius: 50%;
        background: $beakColor2;
      }
      &:after {
        position: absolute;
        content: "";
        z-index: -1;
        width: 37px;
        height: inherit;
        top: 12px;
        left: 2px;
        border-radius: 50%;
        background: $birdColor2;
      }
      div {
        position: absolute;
        width: 43px;
        height: 43px;
        top: -1px;
        left: -1px;
        border-radius: 50% 60% 50% 40%;
        background-clip: padding-box;
        background-color: $beakColor;
        transform: rotate(-45deg);
        &:before {
          position: absolute;
          content: "";
          transform: rotate(45deg);
          width: 17px;
          height: 8px;
          top: 10px;
          left: 20px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
    }
    &--feet {
      position: absolute;
      bottom: 15px;
      width: 100%;
      &:before,
      &:after {
        position: absolute;
        content: "";
        width: 27px;
        height: 21px;
        border-radius: 50%;
        background-color: $feetColor;
        box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.2);
        animation: feet $totaltime linear infinite;
      }
      &:before {
        left: 40px;
      }
      &:after {
        right: 40px;
      }
    }

    &--wire {
      position: absolute;
      z-index: -1;
      width: 500px;
      height: 400px;
      left: -173px;
      top: -215px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-bottom-color: black;
    }
    &--hills {
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 30%;
      top: 230px;
      left: 60px;
      transform: rotate(45deg);
      background: radial-gradient(
        ellipse at top left,
        rgba(170, 217, 93, 1) 0%,
        #a8ff78 100%
      );
      box-shadow: inset 5px 0 12px rgba(0, 0, 0, 0.2);
      &:before,
      &:after {
        position: absolute;
        content: "";
        width: 178px;
        height: 90px;
        border-radius: 50%;
        background: inherit;
        box-shadow: inherit;
      }
      &:before {
        left: -90px;
        top: 30px;
        z-index: -1;
        transform: rotate(-20deg);
      }
      &:after {
        left: 0px;
        top: -55px;
        transform: rotate(120deg);
      }
    }
    &--small {
      top: -50px;
      transform: scale(0.6);
      animation-delay: -$totaltime/3;
      animation-duration: $totaltime * 3;
    }
    &--cloud {
      position: absolute;
      width: 70px;
      height: 24px;
      background: linear-gradient(
        to bottom,
        rgba(242, 249, 254, 1) 5%,
        rgba(214, 240, 253, 1) 100%
      );
      border-radius: 20px;
      top: -20px;
      z-index: -1;
      animation: cloud $totaltime * 2 linear infinite;

      &:before,
      &:after {
        position: absolute;
        content: "";
        background: inherit;
        z-index: -1;
      }
      &:before {
        width: 36px;
        height: 36px;
        top: -18px;
        right: 10px;
        border-radius: 40px;
      }
      &:after {
        width: 20px;
        height: 20px;
        top: -10px;
        left: 10px;
        border-radius: 20px;
      }
    }
    &--bird {
      position: absolute;
      z-index: 1;
      left: 25%;
      top: 19%;
    }
    &--globe {
      margin: 1em 0 0 0;
      position: relative;
      display: inline-block;
      text-align: left;
      width: 400px;
      height: 400px;
      max-width: 90vw;
      max-height: 90vw;
      border-radius: 50%;
      border: 20px solid #f7ad5d;
      box-sizing: border-box;
      background: linear-gradient(
        to bottom,
        rgba(134, 174, 204, 1) 0%,
        #a8ff78,
        100%
      );
      overflow: hidden;
      box-shadow: inset 0 0 80px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
      &:before,
      &:after {
        position: absolute;
        content: "";
        box-sizing: border-box;
        border-radius: 50%;
        z-index: 10;
      }
      &:before {
        height: 94%;
        width: 94%;
        top: 3%;
        right: 3%;
        border: 10px solid transparent;
        border-right-color: rgba(255, 255, 255, 0.3);
        border-right-width: 10px;
        border-right-style: solid;
      }
      &:after {
        top: 50px;
        right: 65px;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, 0.3);
      }
    }
    &--body {
      position: absolute;
      width: 175px;
      height: 186px;
      border-radius: 50%;
      background-clip: padding-box;
      background-color: $birdColor;
      box-shadow: inset 0 0 80px rgba(0, 0, 0, 0.3);
      &:before,
      &:after {
        position: absolute;
        content: "";
        z-index: -1;
        width: 26px;
        height: 93px;
        border-radius: 50%;
        background-color: $birdColor;
        box-shadow: inset 0 0 13px rgba(0, 0, 0, 0.3);
        top: 70px;
        animation: wings $totaltime linear infinite;
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
  }

  &__tutorial {
    &--stop {
      font-size: 1.1rem;
      width: 90%;
      padding: 2px 5px;
      cursor: pointer;
      color: #005f73;
      float: right;
      text-align: right;
    }

    &--start {
      position: absolute;
      top: 29vh;
      margin-left: 4.5px;
      background-color: white;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      z-index: 1;
      box-shadow: 0px 0px 10px 0px #cbdbdf;
      animation: MoveUpDown 1s linear infinite;
    }

    &--start:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.3em;
      bottom: -20px;
      left: 46px;
      box-sizing: border-box;
      z-index: -1;
      border: 0.75em solid white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
    }

    &--click-word {
      position: absolute;
      top: -3rem;
      background-color: white;
      border-radius: 5px;
      font-size: 1.4rem;
      padding: 0.5rem 1rem;
      box-shadow: 0px 0px 10px 0px #cbdbdf;
      animation: MoveUpDown 1s linear infinite;
    }

    &--click-word:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: -20px;
      left: 64px;
      box-sizing: border-box;
      z-index: -1;
      border: 0.75em solid white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
    }

    &--click-phonic {
      position: absolute;
      top: 27vh;
      background-color: white;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      box-shadow: 0px 0px 10px 0px #cbdbdf;
      animation: MoveUpDown 1s linear infinite;
    }
    &--click-phonic:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: -20px;
      left: 15px;
      box-sizing: border-box;
      z-index: -1;
      border: 0.75em solid white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
    }

    &--word-audio {
      position: absolute;
      top: 27vh;
      right: 3rem;
      background-color: white;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      animation: MoveUpDown 1s linear infinite;
      box-shadow: 0px 0px 10px 0px #cbdbdf;
      z-index: 1;
    }
    &--word-audio:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: -20px;
      left: 23px;
      box-sizing: border-box;
      z-index: -1;
      border: 0.75em solid white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
    }

    &--phonic-sound {
      position: absolute;
      top: 50vh;
      background-color: white;
      border-radius: 5px;
      padding: 1rem;
      animation: MoveUpDown 1s linear infinite;
      box-shadow: 0px 0px 10px 0px #cbdbdf;
      z-index: 1;
    }
    &--phonic-sound:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 0px;
      left: 15px;
      z-index: -1;
      box-sizing: border-box;
      border: 0.75em solid white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
    }

    &--tracked-words {
      position: absolute;
      top: 42vh;
      background-color: white;
      border-radius: 5px;
      padding: 1rem;
      z-index: 0;
      animation: MoveUpDown 1s linear infinite;
    }
    &--tracked-words:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: -20px;
      left: 90px;
      box-sizing: border-box;
      border: 0.75em solid white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: 0px 0px 10px 0px #cbdbdf;
    }
  }

  &__back-home-link {
    position: absolute;
    left: 2.3rem;
    top: 0.5rem;
  }

  &__back-home-arrow {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 1.4rem;
    width: 70px;
    // padding: 8px;
    margin: 8px 0 0 8px;
    color: white;

    &:hover {
      left: -30px;
      transition: 0.2s;
    }

    &--img {
      width: 100%;
    }

    &--modalize {
      padding-left: 0;
      width: 50px;
    }
  }

  &__see-more {
    width: 70px;
    padding: 20px;
    cursor: pointer;
    &:hover {
      padding-left: 5px;
      padding-right: 35px;
      transition: 0.2s;
    }

    &--img {
      width: 100%;
    }
  }

  &__container-left {
    position: relative;
    width: auto;
    height: 100vh;
    background-color: #f2e8dc;
  }

  &__container-middle {
    width: 476px;
    position: relative;
    height: 100vh;
    box-shadow: 0 2px 10px 0 #000;
    max-height: 1000px;
    background: #fff;
    transform-style: preserve-3d;
    z-index: 0;
  }

  &__container-page-flip {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    min-width: 13.7vh;
    width: 13.7vh;
    z-index: 999;
    background-color: #f4ede3;
    max-height: 1000px;
    background-image: url(https://study-portal.s3.us-east-2.amazonaws.com/spine-beige.webp);
    background-size: contain;
    background-repeat: no-repeat;
    a {
      background-color: #107e94;
      color: white;
      border-radius: 4px;
      padding: 30px 20px;
      margin: auto;
      width: 50%;
      position: relative;
    }
  }

  &__container-right {
    position: relative;
    width: 40%;
    min-width: 40%;
    max-height: 1000px;
  }

  &__page-container {
    // width: 476px;
    position: relative;
    // transition: opacity 0.5s 0.5s;
    // transform-style: preserve-3d;
    // transform-origin: left center;
    background: white;
    z-index: 0;

    &--active {
      position: relative;
      // transition: opacity 0.5s 0.5s;
      // transform-style: preserve-3d;
      // transform-origin: left center;
      z-index: 1;
    }
  }

  &__page {
    position: relative;
    // height: 100vh;
    display: inline-block;
    backface-visibility: hidden;

    &--active {
      transform: rotateY(0deg);
      transform-origin: left;
      filter: blur(3px);
      transition: 0.2s transform;
      z-index: 1;
    }

    &--next {
      transform: rotateY(-90deg);
      transform-origin: left;
      filter: blur(3px);
      transition: 0.2s transform;
      z-index: 2;
    }

    &--prev {
      transform: rotateY(-90deg);
      transform-origin: left;
      filter: blur(3px);
      transition: 0.2s transform;
      z-index: 2;
    }

    span {
      z-index: 999;
    }
    &--img {
      width: 100%;
      // height: 100vh;
      max-height: 1000px;
    }
  }

  &__cover {
    position: relative;
    height: 100vh;

    &--scroll-down {
      margin: 0;

      // background: #2948ff;
      @include border-radius(100px);
      @include center(100px, 100px);

      &--text {
        position: absolute;
        text-shadow: 2px 2px 2px #000;
        font-size: 2rem;
        color: white;
        left: 32%;
        bottom: 20%;
      }
      &--arrow-container {
        width: 100%;

        /* opacity: 10%; */
      }

      a {
        display: block;
        opacity: 0;
        @include border-radius(100px);
        @include transition(all 0.4s ease);
        @include texthide;
        @include ms(auto);
        @include dim(100%, 100%);
        @include background-size(0 auto);
        @include animation(
          $name: pulse,
          $duration: 1.5s,
          $iterations: infinite
        );
        background-image: url("https://jamesmuspratt.com/codepen/img/arrow-down.svg");
        background-repeat: no-repeat;
        &:before,
        &:after {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          @include dim(100%, 100%);
          background: url("https://jamesmuspratt.com/codepen/img/arrow-down.svg")
            no-repeat center top;
          @include background-size(100% auto);
        }
        &:before {
          @include animation(
            $delay: 0.25s,
            $name: pulse,
            $duration: 1.5s,
            $iterations: infinite
          );
        }
        &:after {
          @include animation(
            $delay: 0.5s,
            $name: pulse,
            $duration: 1.5s,
            $iterations: infinite
          );
        }

        // &:hover {
        //   // @include transform(scale(1));

        //   background-color: rgba(0, 0, 0, 0.3);
        // }
      } // a
    } // .scroll-down

    span {
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100vh;
      max-height: 1000px;
    }
  }

  &__arrows-progress-container {
    // align-self: flex-end;
    margin-bottom: 17vh;
  }

  &__progress-bar {
    border-radius: 3px;
    width: 80%;
    height: 7px;
    display: block;
    margin: auto;
    margin-top: 1rem;
    box-shadow: 0 2px 10px 0 #cbdbdf;

    &::-webkit-progress-bar {
      background-color: white;
      border-radius: 3px;
    }
    &::-webkit-progress-value {
      background-color: #3b96ff;
      border-radius: 3px;
      box-shadow: 0 2px 5px 0 #3b96ff;
    }
    // &::-moz-progress-bar {
    //   /* style rules */
    // }

    &--voice-type-wrapper {
      display: flex;
      flex-direction: row;
    }

    &--voice-type {
      color: black;
      &--selected {
        background: cornflowerblue;
      }
    }

    &--options {
      position: absolute;
      right: 30px;
      top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #3b96ff;
    }

    &--voice {
      margin-right: 10px;
      font-size: 1.4rem;
      color: #3b96ff;
    }

    &--voice-dropdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 51px;
      right: 0%;
      border-radius: 3px;
      background: white;
      padding: 3px;
      height: 210px;
      width: 100%;
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }

    &--voice-dropdown-arrow {
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
      position: absolute;
      top: -5px;
      right: 90px;
    }

    &--voice-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      margin-top: 10px;
      padding-top: 20px;
      border-top: 1px solid #d3d3d3;
    }
    &--icon-wrapper {
      height: 55px;
      width: 55px;
      background: transparent;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 5px 12px 0 rgba(56, 120, 226, 0.53);
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &--icon-outline {
      background: white;
      padding: 16px 12px 8px;
      border-radius: 50%;
      font-size: 1.4rem;
      color: #3b96ff;
      width: 30px;
      height: 30px;
      &--listening {
        background: #3b96ff;
        padding: 16px 12px 8px;
        border-radius: 50%;
        font-size: 1.4rem;
        color: white;
        width: 30px;
        height: 30px;
      }
    }

    &--loader {
      height: 50px;
      display: flex;
      align-items: center;
    }

    &--audio-line {
      display: block;
      position: relative;
      background: linear-gradient(to bottom right, #396afc, #2948ff);
      height: 100%;
      width: 7px;
      border-radius: 50px;
      margin: 0 5px;
      animation: animate-audio 1.2s linear infinite;

      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
      &:nth-child(4) {
        animation-delay: 0.9s;
      }
      &:nth-child(5) {
        animation-delay: 0.6s;
      }
      &:nth-child(6) {
        animation-delay: 0.3s;
      }
      &:nth-child(7) {
        animation-delay: 0s;
      }
    }

    &--font-size {
      padding: 10px;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: space-around;
      display: flex;
    }

    &--dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 56px;
      right: 30px;
      border-radius: 3px;
      background: white;

      padding: 3px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }

    &--dropdown-arrow {
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
      position: absolute;
      top: -5px;
      right: 29px;
    }

    &--modalize {
      margin-top: 7px;
      width: 12rem;
      margin-left: -25px;
    }
  }

  &__word-container {
    position: relative;
    background: white;
    margin: auto;
    padding: 10px 5px;
    font-family: "Fellix-SemiBold";
    line-height: 1.5;
    text-align: left;
    width: 92%;
    z-index: 8;
  }

  &__clickable-word {
    cursor: pointer;
    color: black;
    font-size: 1.7rem;
    font-weight: 700;
    padding: 3px 2px;
    // z-index: 2;
  }

  &__clicked-word {
    background-color: #fff;
    color: black !important;
    font-size: 1.7rem;
    padding: 3px 2px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 #333;
  }

  .fa-arrow-left {
    font-size: 2rem;
    z-index: 100;
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .img-chevron-right {
    align-self: center;
  }

  &__page-number {
    color: #333;
    font-size: 2rem;
    padding: 1rem 0;
    margin: auto;

    &--label-wrapper {
      width: 100px;
      text-align: right;
      display: flex;
      justify-content: center;
    }

    &--modalize-arrow-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &--modalize {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      background-color: white;
      justify-content: center;
      margin-top: 0.2rem;
    }

    &--modalize-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 3.4% 6% 4%;
      align-items: center;
      border-radius: 10px;
      max-width: 400px;
      min-height: 23px;
      background-color: white;
      // opacity: 0.5;
      position: fixed;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      transition: 1s;
    }

    &--label {
      font-size: 1rem;
      display: block;
      margin-bottom: 10px;
      margin-top: 75px;
    }
    &--label-2 {
      font-size: 1rem;
      text-align: right;
      margin-bottom: 10px;
      margin-left: 5px;
    }

    &--show-tracking {
      position: absolute;
      right: 2.1rem;
      top: 0.8rem;
      width: 53px;

      padding: 7px;
      box-shadow: 0 2px 10px 0 #9bbac2;
      border-radius: 8px;
    }

    &--options {
      cursor: pointer;
    }

    &--dropdown {
      display: flex;
      flex-direction: row;
      // width: 13vh;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      border-radius: 3px;
      background: whitesmoke;
      padding: 3px;
      margin-left: 1.2vh;
    }

    &--dropdown-arrow {
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid whitesmoke;
      position: absolute;
      top: -5px;
      right: 5vh;
    }

    &--font-size {
      padding: 10px;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: space-around;
      display: flex;
    }
  }

  &__next-button {
    align-items: center;
    font-weight: 700;
    z-index: 10;
    padding: 1rem 0;
    margin: auto;
    margin-top: 2rem;
    font-size: 1rem;
    opacity: 1;
    height: 10vh;
    border: none;
    background-color: transparent;
    color: black;
    width: 100%;
  }

  &__next-button:hover {
    font-size: 1.1rem;
    border: none;
    transform: translateX(3px);
    transition: 0.2s;
  }

  &__next-arrow--img {
    width: 50%;
  }

  &__back-arrow--img {
    width: 50%;
  }

  &__next-text {
    margin-right: 5px;
    margin-left: 10px;
  }

  &__next-placeholder {
    z-index: 10;
    padding: 1rem 0;
    margin: auto;
    margin-top: 1.2rem;
    height: 7vh;
    background-color: #96bcfe;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    border-radius: 4px;
    cursor: pointer;
  }

  &__back-button {
    font-weight: 700;
    z-index: 10;
    font-size: 1rem;
    margin: auto;
    padding: 1rem 0;
    opacity: 1;
    height: 10vh;
    border: none;
    background-color: transparent;
    color: black;
    width: 100%;
  }

  &__back-button:hover {
    font-size: 1.1rem;
    transform: translateX(-4px);
    border: none;
    transition: 0.2s;
  }

  &__back-text {
    margin-left: 5px;
  }

  &__tracking-container {
    background-color: white;
    height: 43.6vh;
    max-height: 443px;
    overflow-y: scroll;
    box-shadow: 0px 7px 10px 0 #000;
    &--modalize {
      &--show {
        animation-name: slideDownDisplay;
        animation-duration: 1s;
        background-color: white;
        border: 3px solid #3b96ff;
        width: 93.8%;
        height: 40vh;
        overflow-y: scroll;
        overflow-x: hidden;
        left: 3vw;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        top: 60vh;
        position: absolute;
        z-index: 10;
      }
      &--hide {
        animation-name: slideUpHide;
        animation-duration: 1s;
        background-color: white;
        width: 95.5%;
        height: 40vh;
        overflow-y: scroll;
        left: 3vw;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        top: 60vh;
        position: absolute;
      }
    }
    &--title {
      background-color: #f4ede3;
      margin: auto;
      padding: 2% 1%;
      font-size: 1.2rem;
      box-shadow: 0 6px 10px 0px #000;
      display: flex;
      flex-direction: row;

      &--modalize {
        background-color: white;
        text-align: left;
        width: 61%;
        font-size: 1.2rem;
        border: 1px solid #3b96ff;
        box-shadow: 0 2px 10px 0 #cbdbdf;
        display: flex;
        flex-direction: row;
        padding: 4% 7%;
        bottom: 43%;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: fixed;
      }
    }

    &--number {
      font-size: 2rem;
      font-weight: 700;
      color: #96bcfe;
      display: inline-block;
      margin-right: 5px;
      margin-left: 2.5rem;
    }
  }

  &__tracking-section {
    padding: 40px;

    border-bottom: 1px solid #d3d3d3;
  }

  &__modal {
    height: 45.7vh;
    max-height: 457px;
    padding: 4% 7%;
    box-shadow: 0px 4px 9px 0 #000;
    background-color: white;
    &--practice-phonics {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5;
      text-align: center;
      margin: auto;
      margin-left: 1rem;
      text-decoration: underline;
      padding: 3px 10px;
      border-radius: 4px;
    }

    &--chev-right {
      padding-left: 4px;
    }
    &--no-img-div {
      height: 25vh;
      position: relative;
      z-index: 1;

      border-radius: 5px;
      margin: auto;
      opacity: 1;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--no-img {
      font-size: 5rem;
      font-family: "Indie Flower", cursive;
    }
    &--word-sound-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: auto;
    }

    &--phonic-click {
      cursor: pointer;
    }

    &--phonic-sound-icon {
      position: relative !important;
      font-size: 1.2rem;
      color: #3b96ff;
      padding: 12px 0;

      &:hover {
        cursor: pointer;
      }
    }

    &--sound-icon {
      position: relative !important;
      font-size: 1.4rem;
      padding: 20px;

      color: #3b96ff;
      transform: translateY(-7px);
      &:hover {
        cursor: pointer;
      }
    }

    &--img-holder {
      border-radius: 5px;
      margin-bottom: 3%;
      position: relative;
      height: 25vh;
      width: auto;

      img {
        height: 100%;
        position: relative;
        z-index: 0;
        max-width: 90%;
        border-radius: 5px;
        box-shadow: 0 2px 10px 0 #9bbac2;
      }
    }

    &--img {
      height: auto;
      position: relative;
      z-index: 1;
      max-width: 90%;
      border-radius: 5px;
    }

    &--img-div {
      height: 25vh;
      position: relative;
      z-index: 1;

      border-radius: 5px;
      margin: auto;
      opacity: 1;
      object-fit: cover;
    }

    &--img-back {
      position: absolute;
      height: 100%;
      font-weight: 700;
      font-size: 1.4rem;
      left: 0rem;
      bottom: 0rem;
      padding: 34px 23px;
      opacity: 0.4;
      border: none;
      color: white;
      border-radius: 5px;
      background-color: white;
      z-index: 2;
      background-image: linear-gradient(
        to right,
        rgba(37, 37, 37, 0.7),
        rgba(255, 255, 255, 1)
      );
    }

    &--img-back:hover {
      border: none;
      opacity: 0.4;
      background-image: linear-gradient(
        to right,
        rgba(37, 37, 37, 1),
        rgba(255, 255, 255, 1)
      );
    }

    &--img-next {
      position: absolute;
      height: 100%;
      font-weight: 700;
      font-size: 1.4rem;
      right: 0rem;
      bottom: 0rem;
      padding: 34px 23px;
      opacity: 0.4;
      border: none;
      color: white;
      border-radius: 5px;
      background-color: white;
      display: flex;
      flex-direction: row-reverse;
      z-index: 2;
      background-image: linear-gradient(
        to left,
        rgba(37, 37, 37, 0.7),
        rgba(255, 255, 255, 1)
      );
    }

    &--img-next:hover {
      border: none;
      opacity: 0.4;
      background-image: linear-gradient(
        to left,
        rgba(37, 37, 37, 1),
        rgba(255, 255, 255, 1)
      );
    }

    &--img-text {
      transform: translateY(-63px);
      z-index: 10;
      position: relative;
      width: 52%;
      font-size: 1.1rem;
      margin: auto;
    }

    &--placeholder-svg {
      height: 50% !important;
      width: 80%;
      margin-top: 10px;
      padding-top: 20px;
      padding-bottom: 70px;
    }

    &--word-wrapper {
      text-align: left;
      margin-top: 1.1rem;
      margin-bottom: 0.5rem;
      display: flex;
    }

    &--word {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5;
      text-align: left;
      margin-left: 0.2rem;
      background-color: #fff;
      padding: 3px 10px;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
    }

    &--definitions {
      line-height: 1.5;
      padding: 2%;
    }

    &--phonics {
      display: flex;
      flex-direction: row;
      z-index: 0;
    }

    &--phonic-part {
      padding: 5px;
      font-weight: 700;
      font-size: 1rem;

      &-small {
        padding: 5px 3px;
        font-weight: 700;
        font-size: 1rem;
      }
    }

    &--phonic-part-container {
      display: flex;
      flex-direction: column;
    }

    &--phonic-click {
      width: 3rem;
      height: 2rem;
      border: 1px solid #333;

      &-small {
        width: 2rem;
        height: 1.7rem;
        border: 1px solid #333;
      }
    }

    &--phoneme-list {
      width: 400px;
      padding: 10px;
      line-height: 1.5;
    }

    &--remove-word {
      border: none;
      background-color: transparent;
      font-weight: 800;
      margin-left: auto;
      align-self: flex-start;
      padding-top: 17px;
    }

    &--minus-icon {
      padding: 5px 7px;
      border: 3px solid #3b96ff;
      border-radius: 50%;
      color: #3b96ff;
      font-size: 1.3rem;
      margin-top: 5px;
    }

    //modal version
    &--modalize {
      left: 3vw;
      height: 147px;
      width: 80%;
      max-height: 400px;
      padding: 7% 7% 3%;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      border: 3px solid #3b96ff;
      border-radius: 10px;
      background-color: white;
      position: absolute;
      max-width: 430px;
      z-index: 999;
    }

    &--close {
      position: absolute;
      font-size: 150%;
      right: 3%;
      top: 3%;
    }

    &--close-icon {
      color: #3b96ff;
    }

    &--bolt-icon {
      margin-left: 10px;
      font-size: 1.2rem;
    }
  }

  &__tracking-section {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 5% 12%;

    &--login-prompt {
      opacity: 1;
      z-index: 1;
      position: absolute;
      left: 23%;
      bottom: 20%;
      border: none;
      background-color: #50c878;
      color: white;
      box-shadow: 0 2px 10px 0 #5d6f74;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      font-size: 1.2rem;
      border-radius: 6px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 40px 10px;
      width: 50%;
      animation-name: wiggle;

      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      -webkit-animation-name: wiggle;
      -ms-animation-name: wiggle;
      -ms-animation-duration: 4s;

      -webkit-animation-duration: 4s;
      -webkit-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
      -ms-animation-timing-function: ease-in-out;

      &:hover {
        box-shadow: 0 8px 8px 3px #5d6f74;
      }
    }

    &--phonic-part-container {
      display: flex;
      flex-direction: column;
    }

    &--phonic-click {
      width: 2.5rem;
      height: 2rem;
      border: 1px solid black;
      &-small {
        width: 2rem;
        height: 1.7rem;
        border: 0.5px solid black;
      }
    }

    &--remove-word {
      border: none;
      background-color: transparent;
      font-weight: 800;
      margin-left: auto;
    }

    &--minus-icon {
      padding: 5px 7px;
      border: 3px solid #3b96ff;
      border-radius: 50%;
      color: #3b96ff;
      font-size: 1.3rem;
    }

    &--save {
      background-color: white;
      border: none;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 #cbdbdf;
      margin-left: 30px;
    }

    &--save-success {
      font-size: 1rem;
      margin-left: 30px;
      margin-top: 7px;
    }

    &--text {
      font-size: 1.2rem;
      padding-top: 20px;
    }
    &--text-added {
      font-size: 1.4rem;
      color: white;
      text-shadow: 0.5px 0.5px 0.5px rgba(255, 255, 255, 0.7);
    }
    &--text-remove {
      font-size: 1.4rem;
      font-family: "Fellix" !important;
      font-weight: 400;
    }

    &--chevron {
      width: 100%;
      bottom: 0;
      font-size: 2rem;
      display: flex;
      flex-direction: row;

      justify-content: center;
      align-items: center;
      background-color: #3b96ff;
      // box-shadow: 0 2px 10px 0 #50c878;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &--chev-remove {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3rem;
    }
    &--remove-word {
      border: none;
      background-color: trasparent;
      font-family: "Fellix";
      margin-left: auto;

      align-items: center;
      display: flex;

      border-bottom-right-radius: 10px;
    }
    &--remove-word-mobile {
      border: none;
      background-color: #fff;
      font-family: "Fellix";
      margin-left: auto;
      width: 50%;
      align-items: center;
      display: flex;

      border-bottom-right-radius: 10px;
    }
    &--remove-word-button {
      border: none;
      background-color: transparent;

      width: 100%;
    }
  }

  &__phonic-part {
    &--first {
      color: #f9a557;
      font-family: "Fellix-Bold";
    }

    &--second {
      color: #ff7222;
      font-family: "Fellix-Bold";
    }

    &--middle {
      color: #e35072;
      font-family: "Fellix-Bold";
    }

    &--penultimate {
      color: #b466d2;
      font-family: "Fellix-Bold";
    }

    &--last {
      color: #3eb8cb;
      font-family: "Fellix-Bold";
    }
  }

  &__phonic-click {
    &--first {
      background-color: #f9a557;
    }

    &--second {
      background-color: #ff7222;
    }

    &--middle {
      background-color: #e35072;
    }

    &--penultimate {
      background-color: #b466d2;
    }

    &--last {
      background-color: #3eb8cb;
    }
  }

  &__start {
    -webkit-appearance: none;
    border: none;
    background-color: #3b96ff;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.7);
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    padding: 30px 20px;
    margin-bottom: 45vh;
    margin: auto;
    width: 80%;
    appearance: none;
    position: relative;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    animation-name: wiggle;

    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: wiggle;
    -ms-animation-name: wiggle;
    -ms-animation-duration: 4s;

    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
  }

  &__finish {
    -webkit-appearance: none;
    border: none;
    background-color: #3b96ff;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.7);
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    padding: 20px 20px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    appearance: none;
    position: relative;
    vertical-align: bottom;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;

    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 140%;
      height: 100%;
      left: -20%;
      z-index: -1000;
      transition: all ease-in-out 0.5s;
      background-repeat: no-repeat;
    }
    &:before {
      display: none;
      top: -75%;
      background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, transparent 20%, #162947 20%, transparent 30%),
        radial-gradient(circle, #f18659 20%, transparent 20%),
        radial-gradient(circle, #4d7bd7 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #dc659c 15%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #4d7bd7 20%, transparent 20%),
        radial-gradient(circle, #e9b255 20%, transparent 20%);
      background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
        15% 15%, 10% 10%, 18% 18%;
    }
    &:after {
      display: none;
      bottom: -75%;
      background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #162947 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #f18659 15%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #dc659c 20%, transparent 20%),
        radial-gradient(circle, #4d7bd7 20%, transparent 20%),
        radial-gradient(circle, #e9b255 20%, transparent 20%);
      background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
        20% 20%;
    }
  }

  &__animate {
    &:before {
      z-index: 99;
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after {
      z-index: 99;
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}

.story-wrapper {
  width: 100%;
  height: 100vh;
  // background: #000000; /* fallback for old browsers */
  // background: -webkit-linear-gradient(
  //   to bottom,
  //   #102443,
  //   #0f4f7d,
  //   #2948ff
  // ); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(
  //   to bottom,
  //   #102443,
  //   #0f4f7d,
  //   #2948ff
  // ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  pointer-events: none;
}

@media screen and (max-height: 773px) {
  .story {
    &__edit-button {
      padding: 30px 7px;
    }
    &__back-home-arrow {
      width: 50px;
    }

    &__container-middle {
      width: 56.7vh;
    }

    &__start {
      width: 90%;
      font-size: 0.9rem;
    }

    &__finish {
      width: 90%;
      font-size: 0.9rem;
    }
    &__modal {
      height: 49vh;
      &--img-holder {
        height: 26vh;
      }
      &--img-div {
        height: 17vh;
      }

      &--phonic-click {
        width: 2.1rem;
        height: 1.7rem;
      }
      &--phonic-sound-icon {
        font-size: 1.1rem;
      }
    }
    &__tracking-container {
      height: 37.4vh;

      &--number {
        font-size: 1.4rem;
      }

      &--title {
        padding: 2.3% 0.8%;
        font-size: 1.2rem;
      }
    }

    &__tracking-section {
      &--login-prompt {
        bottom: 10%;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .story-wrapper {
    height: unset;
  }
  .story {
    max-height: unset;
    &__container-middle {
      max-height: unset;
      height: auto;
    }
    &__word-container {
      position: relative;
      background: white;
      top: unset;
      right: unset;
      margin: auto;
      // width: 100%;
      padding: 25px 5px;
      // text-align: justify;
    }

    &__cover {
      position: relative;
      height: auto;
      margin-top: 51px;

      span {
        z-index: 999;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    &__page {
      height: auto;
      span {
        z-index: 999;
      }
      &--img {
        width: 100%;
        height: auto;
      }
    }
    // &__container-middle {
    //   overflow: hidden;
    // }

    &__modal {
      &--img-holder {
        height: 47%;
      }
      &--word {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  // .story-wrapper {
  //   overflow: hidden;
  // }
  .story {
    max-height: unset;
    &__tracking-section {
      padding: 5% 2%;
      width: 98%;
      justify-content: space-between;
    }

    &__loading--bird {
      left: 21%;
    }
    // overflow: hidden;
    &__container-middle {
      height: auto;
      max-height: unset;
      // overflow: hidden;
    }
    // &__page {
    //   overflow: hidden;
    // }
    &__page-container {
      // overflow: hidden;
      height: auto;
      margin-bottom: -5px;
      width: unset;
    }
    &__finish {
      width: 70%;
      font-size: 1.2rem;
      margin: 40px auto 20px;
      letter-spacing: 1px;
      text-shadow: none;
      background-color: #50c878;
      box-shadow: 0 2px 10px 0 #50c878;
    }
  }
  .story-wrapper {
    height: unset;
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(2deg);
  }
  10% {
    -webkit-transform: rotate(-2deg);
  }
  20% {
    -webkit-transform: rotate(4deg);
  }
  30% {
    -webkit-transform: rotate(-1deg);
  }
  40% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(2deg);
  }
  10% {
    -ms-transform: rotate(-2deg);
  }
  20% {
    -ms-transform: rotate(4deg);
  }
  30% {
    -ms-transform: rotate(-1deg);
  }
  40% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(2deg);
  }
  10% {
    transform: rotate(-2deg);
  }
  20% {
    transform: rotate(4deg);
  }
  30% {
    transform: rotate(-1deg);
  }
  40% {
    transform: rotate(0deg);
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes slideDownDisplay {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 44vh;
  }
}

@keyframes slideUpHide {
  0% {
    max-height: 44vh;
  }
  100% {
    max-height: 0;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes animate-audio {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}

@keyframes grow {
  0%,
  33% {
    text-shadow: unset;
  }
  33%,
  67% {
    text-shadow: 2px 2px 8px gray;
  }
  67%,
  100% {
    text-shadow: unset;
  }
}
