.user-details {
  display: flex;
  flex-direction: row;
  margin: 1.5rem 1rem 1rem;
  overflow: auto;

  &__children-tracked-words-container {
    min-width: 290px;
    height: 280px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.8);

    border-radius: 7px;
    margin: 0rem 0 0 2rem;
    display: flex;
    flex-direction: row;
    background: linear-gradient(-45deg, #8400ff, #8c00ff, #6c22ff, #7167f7);
    border-radius: 4px;

    overflow: auto;
    padding: 30px;
  }

  &__children-tracked-words {
    &--child-container {
      padding: 20px 30px;
      border-right: 1px solid #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: white;
      text-align: center;
      font-size: 2rem;
      &:first-of-type {
        padding-left: 0px;
      }
      &:last-of-type {
        border: unset;
      }
    }
    &--tracked-words-container {
      border: 1px solid black;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      background-color: rgb(255, 255, 255);
      padding: 30px 37px;
      font-size: 1rem;

      color: black;
    }
    &--tracked-words-number {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  &--remove-button {
    text-align: center;
    align-self: center;
    background: #dc5c5c;
    width: 120px;
    padding: 10px 5px;
    border-radius: 5px;
    color: whitesmoke;
    cursor: pointer;
  }

  &__login-info-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__scroll-prompt-container {
    color: white;
    margin: 1.5rem 1rem 0.5rem;
    text-align: right;
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__scroll-right {
    margin-left: 7px;
  }

  &--unlocked-headline {
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 12px;
  }

  &__wotd-phonic-part {
    &--first {
      background-color: #f9a557;
    }
    &--second {
      background-color: #ff7222;
    }
    &--middle {
      background-color: #e35072;
    }
    &--penultimate {
      background-color: #b466d2;
    }
    &--last {
      background-color: #3eb8cb;
    }
  }

  &__wotd-phonic {
    &--first {
      color: #f9a557;
    }
    &--second {
      color: #ff7222;
    }
    &--middle {
      color: #e35072;
    }
    &--penultimate {
      color: #b466d2;
    }
    &--last {
      color: #3eb8cb;
    }
  }

  &--wotd-phonic-audio,
  &--wotd-phonic-part {
    border-radius: 4px;
    text-align: center;
    width: 57px;
    padding: 4px 8px;
    margin: 10px 0px;
    color: white;
  }

  &--wotd-phonic-name {
    font-size: 1rem;
    padding: 5px 0px;
    margin: 10px 0px;
  }

  &--wotd-phonics-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__lessons {
    padding: 20px 0px;
    margin: 0 1rem;
    background: linear-gradient(-45deg, #6967f7, #3b96ff, #4c83f1, #6798f7);
    min-width: 350px;
    height: 300px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.8);

    border-radius: 7px;
    position: relative;

    display: flex;
    flex-direction: column;

    border-radius: 4px;

    &--scroll {
      position: absolute;
      right: 10px;
      top: -30px;
      color: white;
      font-size: 0.87rem;
    }
    &--arrow {
      margin-left: 8px;
    }

    &--title {
      &--row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0px 20px 10px;
        color: white;
      }
    }

    &--lesson-container {
      padding: 10px 10px;
      overflow-x: scroll;
      overflow-y: hidden;
      border-radius: 7px;

      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 230px;
    }

    &--lesson {
      &--card {
        background-color: white;
        height: 210px;
        min-width: 150px;
        border-radius: 7px;

        margin: 0px 10px;
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.6);
        }
      }

      &--title {
        text-align: center;
        background-color: #333;
        color: white;
        padding: 10px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &--img-holder {
        margin: auto;
      }
      &--img {
        margin: auto;
        width: 120px;
      }
      &--bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(242, 242, 242);
        color: #333;
        border-top: 1px solid rgb(175, 175, 175);
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        padding: 10px;
        font-size: 0.9rem;
      }
    }
  }

  &__minigames {
    padding: 20px 0px;
    margin: 0 1rem;
    background: linear-gradient(-45deg, #04be86, #06cc80, #01b3b3);
    min-width: 350px;
    height: 300px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.8);

    border-radius: 7px;

    display: flex;
    flex-direction: column;

    border-radius: 4px;

    &--title {
      margin-bottom: 10px;
      margin-left: 20px;
      color: white;
    }

    &--minigame-container {
      padding: 10px 10px;
      overflow-x: scroll;
      overflow-y: hidden;
      border-radius: 7px;

      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 230px;
    }

    &--minigame {
      &--card {
        background-color: white;
        height: 160px;
        min-width: 130px;
        border-radius: 7px;
        padding: 10px;
        margin: 0px 10px;
        display: flex;
        flex-direction: column;

        justify-content: space-evenly;

        transition: 0.2s;
        cursor: pointer;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.6);
        }
      }

      &--title {
        text-align: center;
      }

      &--img {
        width: 70px;
      }

      &--img-holder {
        background-color: #333;
        height: 120px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &--bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #787878;
      }
    }
  }

  &__worksheets {
    padding: 20px 0px;
    margin: 0 1rem;
    background: linear-gradient(-45deg, #ff7998, #e73c7e, #ff674d);
    min-width: 350px;
    height: 300px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.8);

    border-radius: 7px;

    display: flex;
    flex-direction: column;

    border-radius: 4px;

    &--title {
      color: white;
    }

    &--worksheet-container {
      padding: 10px 10px;
      overflow-x: scroll;
      overflow-y: hidden;
      border-radius: 7px;

      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 230px;
    }

    &--worksheet {
      &--card {
        background-color: white;
        height: 210px;
        min-width: 150px;
        border-radius: 7px;

        margin: 0px 10px;
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.6);
        }
      }

      &--title {
        text-align: center;
        background-color: #333;
        color: white;
        padding: 10px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &--img-holder {
        margin: auto;
      }
      &--img {
        margin: auto;
        width: 120px;
      }
      &--see-more {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 60px;
      }
    }
  }

  &--word-day-container {
    padding: 20px 0px 20px 34px;
    margin-left: 1rem;
    background: linear-gradient(to bottom right, #b0c9ff, #fff, #b0c9ff);
  }

  &--wotd-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    padding-left: 10px;
  }

  &--wotd-title {
    font-size: 0.93rem;
    margin-right: 25px;
    width: 80px;
    transform: translateX(-8px);
  }

  &--word-of-the-day {
    font-size: 1.5rem;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 80%;

    align-items: center;
  }

  &--coming-soon {
    align-self: center;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    padding: 18px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    margin-bottom: 23px;
    background: #2948ff; /* fallback for old browsers */
    // background: -webkit-linear-gradient(
    //   to bottom right,
    //   #4eb6ff,
    //   #2948ff
    // ); /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to bottom right, #4eb6ff, #2948ff);
    color: white;
  }

  &--recently-learned-container {
    align-items: center;
  }

  &__add-child {
    margin: 0 1rem 0.5rem;
    padding: 0.5rem;
    min-width: 150px;
    height: 585px;
    background: rgba(255, 255, 255, 0.1);
    border: 3px solid #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: "Fellix-Bold";
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: 0.2s;

    &--text {
      position: absolute;
      top: 48%;
      font-size: 1.1rem;
      font-weight: 700;
    }
    &--plus {
      position: absolute;
      top: 52%;
      font-size: 2rem;
      font-weight: 700;
    }
    &:hover {
      background-color: white;
      border-radius: 8px;
      color: #333;
      box-shadow: 0 2px 10px 0 #333;
    }
  }

  &--grade {
    cursor: pointer;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    padding: 2px 10px;
    background-color: #2948ff;
    color: white;
    border-radius: 4px;
    font-family: "Fellix-SemiBold";

    &:hover {
      box-shadow: 0 5px 15px #2948ff;
      transform: translateY(-2px);
    }
  }

  &--arrow-right {
    width: 30px;
  }

  &--my-tutor-wrapper {
    width: 90%;
    height: 130px;
    padding: 18px 12px;
    // box-shadow: 0 2px 10px 0 #cbdbdf;
    border-bottom: 1px solid #eeeeee;
    text-align: center;
    margin: auto;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 12px 0 #cbdbdf;
    }
  }

  &--tutor-selected-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 10px;
  }

  &--tutor-name {
    margin-bottom: 7px;
  }

  &--tutor-label {
    margin-top: 12px;
    width: 70px;
    color: #808080;
  }

  &--user-icon {
    font-size: 2.5rem;
    color: #2948ff;
  }

  &--tutor-selected {
    padding: 43px;
    margin-left: 23px;
    box-shadow: 0 3px 12px 0 #cbdbdf;
    border-radius: 7px;
  }

  &--get-tutor-wrapper {
    margin: auto;
  }

  &--tutor-review-wrapper {
    width: 95%;
    height: 210px;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    border-radius: 4px;
    text-align: center;
    margin: auto;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 12px 0 #cbdbdf;
    }
  }

  &--testimonial-text {
    margin: 5px auto;
  }

  &--5-stars {
    width: 70%;
  }

  &--reading-level-container {
    margin: 0rem 1rem;

    padding: 30px 10px 10px;

    font-size: 1.2rem;
    text-align: left;
    display: flex;

    flex-direction: row;
    align-items: center;
    border-top: 1px solid #eeeeee;
    // box-shadow: 0 2px 10px 0 #cbdbdf;
  }
  &--tracked-container {
    margin: 0rem 1rem;

    padding: 20px 10px 0px;
    font-size: 1.2rem;
    text-align: left;
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eeeeee;
    // box-shadow: 0 2px 10px 0 #cbdbdf;
  }

  &--graph-title {
    margin: 0rem 1rem;

    padding: 0px 10px;
    font-size: 1.2rem;
    text-align: left;
    display: flex;
    padding-top: 43px;
    border-top: 1px solid #eeeeee;
    color: #333;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    // box-shadow: 0 2px 10px 0 #cbdbdf;
  }

  &--card-child-wrapper {
    margin: 0rem 1rem;
    padding: 20px 10px;
    margin-top: 10px;

    font-size: 1.2rem;
    text-align: left;
    display: flex;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    // box-shadow: 0 2px 10px 0 #cbdbdf;
  }

  &--child-label {
    position: relative;
    font-weight: 700;
    margin-right: 7px;
  }

  &--reading-level-blurb {
    position: absolute;
    left: -7px;
    top: 37px;
    padding: 1rem 0.1rem;
    width: 8rem;
    font-size: 1rem;
    height: 20px;
    text-align: center;
    z-index: 1;
    border: 2px solid #2948ff;
    border-radius: 7px;
    background-color: white;
    color: #2948ff;
    text-decoration: underline;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    transition: all 0.25s;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 0;
      left: 50%;
      box-sizing: border-box;
      z-index: -1;
      border-top: 0.75em solid white;
      border-left: 0.75em solid white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: 5px -5px 10px -1px #cbdbdf;
    }
  }

  &--div-wrapper {
    height: 100%;
  }

  &--blurb-on-enter {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
  }

  &--change-grade {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: 10px;
  }

  &--change-reading-level {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 65px;
  }

  &--change-text {
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: underline;
    color: #747474;
    margin-left: 10px;
  }

  &--school {
    margin-right: 1.25rem;
  }

  &--child {
    margin-right: 1.25rem;
    font-size: 1.2rem;
    font-family: "Fellix-SemiBold";
    box-shadow: 0 2px 10px 0 #cbdbdf;
    padding: 2px 10px;
    background-color: #2948ff;
    color: white;
    border-radius: 4px;
    transition: 0.2s;

    &:hover {
      box-shadow: 0 5px 15px #2948ff;
      transform: translateY(-2px);
    }
  }

  &--card {
    margin: 0 1rem 0.5rem;
    padding: 0.5rem;
    min-width: 370px;
    max-width: 370px;
    height: 590px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 #333;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
  }

  &--card:first-child {
    margin-left: 1rem;
  }

  &--card-identifier-wrapper {
    padding: 40px 12px;
    border-radius: 4px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2rem;
    // box-shadow: 0 2px 10px 0 #cbdbdf;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  &--identifier {
    position: relative;
    font-weight: 700;
    margin-right: 7px;
  }
  &--card-refer-wrapper {
    margin-bottom: 0rem;

    padding-left: 1rem;
    padding-top: 4rem;
    font-size: 1.2rem;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #eeeeee;
  }
  &--refer {
    position: relative;
    font-weight: 700;
    margin-right: 7px;
    text-align: center;
    color: #333;
  }

  &--details-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &--button-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.3rem 1rem 0 1rem;
  }

  &--text {
    font-size: 1rem;
    margin-bottom: 4px;
    width: 150px;
    text-align: center;
  }

  &--text-tutor {
    font-size: 0.8rem;
    margin-bottom: 4px;
    text-align: center;
    margin-top: 7px;
  }

  &--invite-button {
    border: none;
    border-radius: 4px;
    margin-top: 0.25rem;
    background-color: #50c878;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    padding: 20px 0;
    color: white;
    font-weight: 700;
    width: 150px;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 5px 15px #50c878;
    }
  }

  &--tutor-button {
    border: none;
    border-radius: 4px;
    margin-top: 0.25rem;
    background: linear-gradient(180deg, #f26931, #f15b49, #ee3f7a);
    box-shadow: 0 2px 10px 0 #cbdbdf;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    padding: 13px 0;
    font-size: 1.2rem;
    color: white;
    font-weight: 700;

    width: 300px;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }

  &--sign-in-button {
    border: none;
    border-radius: 4px;
    margin-top: 0.25rem;
    background-color: #2948ff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    padding: 20px 0;
    color: white;
    font-weight: 700;
    width: 150px;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 5px 15px #2948ff;
    }
  }

  &--stat-wrapper {
    display: flex;
    flex-direction: row;

    margin-bottom: 1rem;

    padding-top: 0.3rem;
    font-size: 1.2rem;
    text-align: left;
    align-items: center;
    margin-top: 0.8rem;
  }

  &--stat {
    font-size: 2rem;
    font-weight: 700;
    color: #2948ff;
    display: inline-block;
    margin-top: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      text-shadow: 1px 3px 4px #aba9d0;
    }
  }

  &--stat-identifier {
    font-size: 1.1rem;
    font-weight: 700;
    width: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  &--what-is-this {
    font-size: 0.7rem;
    margin-bottom: 4px;
    align-self: start;
  }

  &--level {
    color: #fff;
    font-family: "Fellix-Bold";
    border-radius: 4px;
    padding: 5px 9px;
    width: 16px;
    height: 23px;
    text-align: center;
    margin: 4px 0px 2px;
  }
}

@media only screen and (max-width: 500px) {
  .user-details {
    display: flex;
    flex-direction: row;
    margin: 0.2rem 0rem 1rem;
    overflow: auto;

    &--card {
      min-width: 340px;
      max-width: 340px;
    }
    &--card:first-child {
      margin-left: 0.6rem;
    }

    &--change-reading-level {
      margin-left: 35px;
    }
  }
}
