.edit-story {
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;

  &__submit {
    background: #ccc;
    border-radius: 5px;
    width: 75%;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    margin-bottom: 2rem;
  }

  &__image-upload-container {
    display: flex;
    flex-direction: column;
    width: 150px;
  }

  input {
    width: 3rem;
  }

  span {
    margin-bottom: 10px;
  }

  textarea {
    min-height: 10rem;
    width: 20rem;
    height: 10rem;
  }
}
